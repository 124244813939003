import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import CountdownCell from './CountdownCell';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient';

// Create column helper
const columnHelper = createColumnHelper();

export default function ComplexTable({ tableData, toggleEnableFunction }) {
  const navigate = useNavigate();
  const handleReservationView = (reservationId) => {
    // console.log(reservationId, 'Reservation view function called');
    localStorage.setItem('reservationViewId', reservationId);
    navigate(`/admin/addOrder`);
  };

  const [sorting, setSorting] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);

  const delt_reservation = async (id) => {
    // Show SweetAlert confirmation dialog
    // console.log(id);
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!", // Confirmation message
      icon: 'warning',
      showCancelButton: true, // Show cancel button
      confirmButtonColor: '#d33', // Color for the confirm button
      cancelButtonColor: '#3085d6', // Color for the cancel button
      confirmButtonText: 'Yes', // Text for the confirm button
    });

    if (result.isConfirmed) {
      // If user confirmed, try to delete the reservation
      try {
        // Call the API to delete the reservation
        const response = await apiClient.delete(`delt_reservations/${id}`);

        // Handle the response from the server
        // console.log(response.data.message); // e.g. "Reservation deleted successfully"
        Swal.fire('Deleted!', response.data.message, 'success'); // Show success message
      } catch (error) {
        // Handle errors from the API call
        //  console.error(
        //     'Error deleting reservation:',
        //     error.response?.data?.message || error.message,
        //   );
        Swal.fire(
          'Error!',
          'There was a problem deleting the reservation.',
          'error',
        );
      }
    }

    toggleEnableFunction();
  };
  // Define columns
  const columns = [
    columnHelper.accessor('sellerPhone', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Seller
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const phoneNumber = info.row.original.sellerPhoneNumber || ''; // Dynamically set seller's phone number
              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* Seller Name or Info */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || '295'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('user', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          User
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const phoneNumber = info.row.original.userPhone || ''; // Dynamically set seller's phone number
              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* Seller Name or Info */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || 'Nabeel Ijaz '}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('market', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Market
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor('productId', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Product ID
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'N/A'}
        </Text>
      ),
    }),

    columnHelper.accessor('createdAt', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Remaining Time
        </Text>
      ),
      cell: (info) => (
        <Text
          color="#ff0000" // Red color
          fontSize="lg"
          fontWeight="200"
          fontFamily="'Share Tech Mono', monospace" // Font family
          textAlign="center"
          position="relative"
          left="1%"
          top="1%"
          transform="translate(-1%, -1%)"
          textShadow="0 0 20px rgba(210, 1, 2, 1), 0 0 20px rgba(210, 1, 2, 0)" // Text shadow
        >
          {/* {info.getValue()} */}
          <CountdownCell
            createdAt={info.getValue()}
            toggleEnableFunction={toggleEnableFunction}
            id={info.row.original.reservationId}
          />
        </Text>
      ),
    }),

    columnHelper.accessor('image', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Image
        </Text>
      ),
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Product"
          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
          onClick={() => {
            setSelectedImage(info.getValue());
            setIsOpen(true);
          }}
        />
      ),
    }),

    columnHelper.accessor('action', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Action Buttons
        </Text>
      ),
      cell: (info) => (
        <Flex justify="center" align="center">
          {userRole === 'Pm' && (
            <Button
              color="green.500"
              width="120px"
              borderColor="green.500"
              variant="outline"
              borderRadius="full"
              onClick={() =>
                handleReservationView(info.row.original.reservationId)
              }
            >
              Create Order
            </Button>
          )}
          <Button
            color="white"
            borderColor={true ? 'red.500' : 'green.500'} // Replace 'true' with your actual logic
            bg={true ? 'red.500' : 'green.500'} // Replace 'true' with your actual logic
            variant="outline"
            borderRadius="full"
            onClick={() => delt_reservation(info.row.original.reservationId)}
            style={{ marginLeft: '5px' }} // Add some space between buttons
            _hover={{
              bg: true ? '#8B0000' : '#005700', // Dark red or dark green on hover
              borderColor: true ? '#8B0000' : '#005700', // Dark border color
              color: 'white', // Keep text color white
            }}
          >
            Release
          </Button>
        </Flex>
      ),
    }),
  ];

  // Initialize the table
  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleActionClick = (rowData, actionType) => {
    // console.log(`${actionType} clicked for:`, rowData);
    // Implement additional action logic here if needed
  };
  return (
    <Card
      flexDirection="column"
      w="100%"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      borderTopLeftRadius="0"
      borderTopRightRadius="0"
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="center"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Box ml={2}>
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === 'asc'
                            ? '↑'
                            : '↓'
                          : null}
                      </Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={`${cell.id}_${row.id}`}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '10px', md: '20px', lg: 'auto' }}
                    borderColor="transparent"
                    textAlign="center"
                    verticalAlign="middle"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {cell.column.columnDef.cell === 'image' ? (
                        <img
                          src={cell.getValue()}
                          alt="Image"
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            margin: '0 auto', // Center the image
                            padding: '4px', // Add some padding around the image
                          }}
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </div>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={{ base: 'md', sm: 'lg' }}
        isCentered
      >
        <ModalOverlay />
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
        >
          <ModalContent
            borderRadius="md"
            boxShadow="lg"
            p={2}
            mt={{ base: 'center', sm: '140' }}
          >
            <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
              Image Preview
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={6}
            >
              <Image
                src={selectedImage}
                alt="Enlarged Product"
                maxW="100%"
                maxH="80vh"
                objectFit="contain"
                borderRadius="md"
              />
            </ModalBody>
          </ModalContent>
        </motion.div>
      </Modal>
    </Card>
  );
}
