import { Box, Grid, Text, Skeleton } from "@chakra-ui/react"; 
import axios from "axios";
import React, { useEffect, useState } from "react";
import Banner from "views/admin/profile/components/ProfileBox";
import General from "views/admin/profile/components/ProfileInformation";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/profile/male.jpg";
import apiClient from '../../../authclient/authclient';
import { useNavigate } from 'react-router-dom';

export default function Overview() {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    
    const fetchUserData = async () => {
      try {
        const response = await apiClient.get('profile'); // Make sure the correct endpoint is passed
        // console.log(response.data);
        setUserData(response.data || {}); // Use empty object as default if no data is returned
      } catch (err) {
        if (err.response && err.response.status === 403) {
          navigate('/login'); // Redirect to login if 403 Forbidden error occurs
        } else {
          setError('Failed to fetch user data.'); // Set a general error message
          //console.error(err); // Log the error for debugging
        }
      } finally {
        setLoading(false); // Stop loading, no matter the outcome
      }
    };
    
    fetchUserData();
  }, []);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid
          templateColumns={{ base: "1fr", lg: "0.6fr 1.2fr" }}
          templateRows={{ base: "repeat(1, 1fr)", lg: "1fr" }}
          gap={{ base: "15px", xl: "5px" }}
        >
          <Skeleton height="200px" borderRadius="md" />
          <Skeleton height="365px" borderRadius="md" />
        </Grid>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns={{ base: "1fr", lg: "0.6fr 1.2fr" }}
        templateRows={{ base: "repeat(1, 1fr)", lg: "1fr" }}
        gap={{ base: "15px", xl: "5px" }}
      >
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 1 / 2" }}
          banner={banner}
          avatar={avatar}
          name={userData.name || 'Unknown User'}
          job={userData.userRole || ''}
          userId={userData.userId || ''}
          posts={userData.posts || '0'}
          followers={userData.followers || '0'}
          following={userData.following || '0'}
        />
        
        <General
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          width={{ base: "100%", lg: "100%" }}
          userData={userData}
        />
      </Grid>
    </Box>
  );
}
