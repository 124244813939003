import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, Text, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import SignIn from '../../views/auth/login';
import apiClient from "../../authclient/authclient"; // Correct API client import

const Projects = () => {
  const [loading, setLoading] = useState(false);  // Track loading state
  const [error, setError] = useState(null);  // Track errors
  const navigate = useNavigate();  // For navigation after logout

  // Logout function to handle user logout and API call
  const logout = async () => {
    try {
      setLoading(true);  // Start loading animation
      setError(null);  // Reset error state

      // Sending POST request to the /logout endpoint
      const response = await apiClient.post('/logout', {}, { withCredentials: true });

      // Handle successful logout
      // console.log(response.data.message);  // "Logout successful"
      setLoading(false);  // Stop loading

      // Redirect to login page after successful logout
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      setLoading(false);  // Stop loading even in case of error
      setError('Error during logout: ' + error.message);  // Set the error message
      //console.error('Error during logout:', error);
    }
  };

  // Trigger logout when the component mounts
  useEffect(() => {
    logout();  // Automatically call logout on page load
  }, []);  // Empty dependency array to run only once when component mounts

  return (
    <Box>
      {/* Display loading spinner or error message */}
      {loading && (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" color="blue.500" />
          <Text fontSize="lg" color="blue.500" ml={4}>
            Logging out...
          </Text>
        </Flex>
      )}


      {/* If logged out successfully, render the login page */}
      {/* {!loading && !error && <SignIn />} */}
      {!loading && <SignIn />}
    </Box>
  );
};

export default Projects;
