import { Box, Grid, Text, Skeleton } from "@chakra-ui/react"; 
import axios from "axios";
import React, { useEffect, useState } from "react";
import Banner from "views/admin/products/components/ProfileBox";  // Update based on actual component
import General from "views/admin/products/components/ProfileInformation";  // Update based on actual component
import banner from "assets/img/auth/banner.png";  // Replace with actual banner image
import avatar from "assets/img/profile/male.jpg";  // Replace with actual product image or default
import apiClient from '../../../authclient/authclient';  // API client for making requests
import { useNavigate } from 'react-router-dom';

export default function Overview() {
  const [productData, setProductData] = useState({}); // State for product data
  const [loading, setLoading] = useState(true);  // State for loading status
  const [error, setError] = useState(''); // State for error handling
  const navigate = useNavigate();
  const fetchProductData = async () => {
    try {
      // Fetch product id from localStorage, it could be set in another part of the app
      const productId = localStorage.getItem('ViewproductId');
      if (!productId) {
        navigate('/admin/productlist');  // Redirect if no product ID found
      }

   
     
      // Make API call to fetch product details
      const response = await apiClient.get(`viewProduct/${productId}`);
      // console.log(response.data);
      setProductData(response.data || {});  // Use empty object as default if no data
    } catch (err) {
      setError('Failed to fetch product data.');
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProductData();
  }, []);  // Empty dependency array to run only once on mount

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid
          templateColumns={{ base: "1fr", lg: "0.6fr 1.2fr" }}
          templateRows={{ base: "repeat(1, 1fr)", lg: "1fr" }}
          gap={{ base: "15px", xl: "5px" }}
        >
          <Skeleton height="200px" borderRadius="md" />
          <Skeleton height="365px" borderRadius="md" />
        </Grid>
      </Box>
    );
  }

  return ( 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} mb={{ base: "20px", md: "30px", xl: "40px" }} width="100%">
      <Grid 
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)',    xl: '35% 65%',       }}
        gap={{ base: '15px', xl: '10px' }}
  
      >

<Grid 
        templateColumns={{ base: 'repeat(1, 2fr)', md: 'repeat(1, 2fr)', lg: 'repeat(1, 2fr)', xl: 'repeat(1, 2fr)' }}
        gap={{ base: '15px', xl: '5px' }}
        width="100%"
      >
        {/* Banner for Product Image and Name */}
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 2 / 2" }}
          banner={banner}  // Banner image for the product
          avatar={productData.image1Url || avatar}  // Use product image or default avatar
          OrderId={productData.ProductID }
          name= 'Product Picture' // Display product name
        />

        {/* Banner for additional product details (e.g., price, market, etc.) */}
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 2 / 2" }}
          banner={banner}  // Banner image for the product
          avatar={productData.image2Url || avatar}  // Use product image or default avatar
          OrderId={productData.ProductID }
          name={productData.productName || 'AMZ Picture'}  // Display product name
        />
      
     </Grid>
      {/* General Information Section */}
      <General
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
        minH="365px"
        width={{ base: "100%", lg: "100%" }}
        productData={productData}  // Pass product data to the General component
        toggleEnableFunction={fetchProductData} 
      />
       </Grid>
    </Box>
  );
}
