import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  MdCancel,
  MdCheckCircle,
  MdOutlineError,
  MdShoppingCart,
  MdStar,
  MdCheck,
  MdMoneyOff,
  MdDelete,
} from 'react-icons/md';
import Card from 'components/card/Card';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
// Create column helper
const columnHelper = createColumnHelper();

export default function ComplexTable({ tableData, phoneNumber }) {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);
  // Define columns
  const viewOrderHandler = (orderId) => {
    // console.log(orderId, 'view order function called');

    // Set the orderId in localStorage to access it later
    localStorage.setItem('orderId', orderId);

    // Navigate to the order info page (assuming you're using react-router)
    navigate(`/admin/orderinfo`);
  };

  const columns = [
    columnHelper.accessor('sellerPhone', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Seller
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const url = `https://wa.me/${info.getValue()}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* Seller Phone Number */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || ''}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('userPhone', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          User
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const url = `https://wa.me/${info.getValue()}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* User Phone Number */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || ''}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('orderNo', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Order Number
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || ''}
        </Text>
      ),
    }),

    columnHelper.accessor('customerEmailAddress', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Customer Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'nnabeelijaznabinoor@gmail.com'}
        </Text>
      ),
    }),

    columnHelper.accessor('productId', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Product
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '10001'}
        </Text>
      ),
    }),
    columnHelper.accessor('productType', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Type
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'Review'}
        </Text>
      ),
    }),

    columnHelper.accessor('status', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();

        let icon;
        let iconColor;
        let displayText;

        switch (status) {
          case 'Ordered':
            icon = MdShoppingCart;
            iconColor = 'blue.500';
            displayText = 'Ordered';
            break;
          case 'Reviewed':
            icon = MdStar;
            iconColor = 'yellow.500';
            displayText = 'Reviewed';
            break;
          case 'Delivered':
            icon = MdCheck;
            iconColor = 'green.500';
            displayText = 'Delivered';
            break;
          case 'OnHold':
            icon = MdOutlineError;
            iconColor = 'orange.500';
            displayText = 'On Hold';
            break;
          case 'Cancelled':
            icon = MdCancel;
            iconColor = 'red.500';
            displayText = 'Canceled';
            break;
          case 'Refunded':
            icon = MdMoneyOff;
            iconColor = 'purple.500';
            displayText = 'Refunded';
            break;
          case 'Deleted':
            icon = MdDelete;
            iconColor = 'red.500';
            displayText = 'Deleted';
            break;
          case 'Completed':
            icon = MdCheckCircle;
            iconColor = 'green.600';
            displayText = 'Completed';
            break;
          default:
            icon = null;
            iconColor = 'gray.400';
            displayText = 'Unknown';
            break;
        }

        return (
          <Flex align="center" p={2}>
            {icon && (
              <Icon w="24px" h="24px" me="5px" color={iconColor} as={icon} />
            )}
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {displayText}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image1Url', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Image
        </Text>
      ),
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Product"
          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
          onClick={() => {
            setSelectedImage(info.getValue());
            setIsOpen(true);
          }}
        />
      ),
    }),
    columnHelper.accessor('createDate', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Create Date
        </Text>
      ),
      cell: (info) => {
        const createDate = info.getValue();
        const formattedDate = createDate
          ? format(new Date(createDate), 'MM/dd/yyyy HH:mm:ss') // Format both date and time
          : '00/00/0000 00:00:00'; // Default date and time
        return (
          <Text color={textColor} fontSize="lg" fontWeight="200">
            {formattedDate}
          </Text>
        );
      },
    }),
    columnHelper.accessor('view', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        ></Text>
      ),
      cell: (info) => (
        <Button
          color="white"
          borderColor="#3965ff"
          variant="outline"
          bg="#3965ff"
          width="100px"
          borderRadius="full"
          onClick={() => viewOrderHandler(info.row.original._id)}
        >
          View
        </Button>
      ),
    }),
  ];

  // Initialize the table
  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleWhatsAppClick = (sellerName) => {
    const message = `Hello ${sellerName}, I would like to inquire about your product.`;
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message,
    )}`;
    window.open(url, '_blank');
  };

  const handleConfirmation = (rowData, actionType) => {
    Swal.fire({
      title: `Are you sure?`,
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        handleActionClick(rowData, actionType);
      }
    });
  };

  const handleActionClick = (rowData, actionType) => {
    // console.log(`${actionType} clicked for:`, rowData);
    // Implement additional action logic here if needed
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      borderTopLeftRadius="0"
      borderTopRightRadius="0"
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="center"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Box ml={2}>
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === 'asc'
                            ? '↑'
                            : '↓'
                          : null}
                      </Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={`${cell.id}_${row.id}`}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '10px', md: '20px', lg: 'auto' }}
                    borderColor="transparent"
                    textAlign="center"
                    verticalAlign="middle"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {cell.column.columnDef.cell === 'image' ? (
                        <img
                          src={cell.getValue()}
                          alt="Image"
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            margin: '0 auto', // Center the image
                            padding: '4px', // Add some padding around the image
                          }}
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </div>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={{ base: 'md', sm: 'lg' }}
        isCentered
      >
        <ModalOverlay />
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
        >
          <ModalContent
            borderRadius="md"
            boxShadow="lg"
            p={2}
            mt={{ base: 'center', sm: '140' }}
          >
            <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
              Image Preview
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={6}
            >
              <Image
                src={selectedImage}
                alt="Enlarged Product"
                maxW="100%"
                maxH="80vh"
                objectFit="contain"
                borderRadius="md"
              />
            </ModalBody>
          </ModalContent>
        </motion.div>
      </Modal>
    </Card>
  );
}
