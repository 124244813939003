import React, { useState, useEffect } from 'react'; // Ensure useState and useEffect are imported

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
  Stack,
} from '@chakra-ui/react';
import {
  MdPerson,
  MdHome,
  MdLogout,
  MdVisibility,
  MdSettings,
  MdDelete,
  MdReceipt,
  MdLock,
  MdWarning,
  MdEvent,
  MdShoppingCart,
  MdAttachMoney,
  MdRequestPage,
  MdAccountCircle,
  MdAccountBalance,
  MdBookmark,
  MdNotifications,
  MdLockReset,
  MdNote,
  MdAdd,
  MdBlock,
  MdEmojiPeople,
  MdError,
  MdCheckCircle,
  MdCheck,
  MdMoneyOff,
  MdCancel,
  MdHourglassEmpty,
  MdList,
  MdHistory,
  MdStar,
  MdLockClock,
  MdPassword,
  MdMoney,
  MdManageHistory,
  MdPlaylistPlay,
  MdGroup,
} from 'react-icons/md';
import { FaPaypal, FaHistory } from 'react-icons/fa';

import SidebarLinks from 'components/sidebar/components/Links';
import {
  renderThumb,
  renderTrack,
  renderView,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';
import Brand from './components/Brand';
import SidebarCard from './components/SidebarCard';

import { IoMenuOutline } from 'react-icons/io5';

function getRoutes(userRole) {
  const routes = [
    {
      name: 'Dashboard',
      layout: '/admin',
      path: '/dashboard',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    },
    ...(userRole === 'Admin' || userRole === 'SuperAdmin'
      ? [
          {
            name: 'Users',
            layout: '/admin',
            icon: (
              <Icon as={MdGroup} width="20px" height="20px" color="inherit" />
            ),
            subroutes: [
              {
                name: 'Add New User',
                layout: '/admin',
                path: '/add-user',
                icon: (
                  <Icon as={MdAdd} width="20px" height="20px" color="inherit" />
                ),
              },
              {
                name: 'All Users',
                layout: '/admin',
                path: '/user-list',
                icon: (
                  <Icon
                    as={MdEmojiPeople}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
              },
              {
                name: 'Active Users',
                layout: '/admin',
                path: '/active-users',
                icon: (
                  <Icon
                    as={MdVisibility}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
              },
              {
                name: 'Blocked Users',
                layout: '/admin',
                path: '/blocked-users',
                icon: (
                  <Icon
                    as={MdBlock}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
              },
              {
                name: 'Warning Users',
                layout: '/admin',
                path: '/warning-users',
                icon: (
                  <Icon
                    as={MdError}
                    width="20px"
                    height="20px"
                    color="inherit"
                  />
                ),
              },
            ],
          },
        ]
      : []),
    // Orders

    {
      name: 'Orders',
      layout: '/admin',
      icon: (
        <Icon as={MdShoppingCart} width="20px" height="20px" color="inherit" />
      ),
      subroutes: [
        {
          name: 'All',
          layout: '/admin',
          path: '/vieworders',
          icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,
        },
        {
          name: 'Ordered',
          layout: '/admin',
          path: '/ordered',
          icon: (
            <Icon
              as={MdShoppingCart}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: 'Reviewed',
          layout: '/admin',
          path: '/reviewed',
          icon: <Icon as={MdStar} width="20px" height="20px" color="inherit" />,
        },
        {
          name: 'Delivered',
          layout: '/admin',
          path: '/delivered',
          icon: (
            <Icon as={MdCheck} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: 'Refunded',
          layout: '/admin',
          path: '/refunded',
          icon: (
            <Icon as={MdMoneyOff} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: 'Cancelled',
          layout: '/admin',
          path: '/cancelled',
          icon: (
            <Icon as={MdCancel} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: 'On Hold',
          layout: '/admin',
          path: '/onhold',
          icon: (
            <Icon
              as={MdHourglassEmpty}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: 'Review Deleted',
          layout: '/admin',
          path: '/deleted',
          icon: (
            <Icon as={MdDelete} width="20px" height="20px" color="inherit" />
          ),
        },
        {
          name: 'Completed',
          layout: '/admin',
          path: '/completed',
          icon: (
            <Icon
              as={MdCheckCircle}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
      ],
    },
    // Products
    {
      name: 'Products',
      layout: '/admin',
      icon: <Icon as={MdReceipt} width="20px" height="20px" color="inherit" />,

      subroutes: [
        ...(userRole === 'Seller'
          ? [
              {
                name: 'Add Product',
                layout: '/admin',
                path: '/addproduct',
                icon: (
                  <Icon as={MdAdd} width="20px" height="20px" color="inherit" />
                ),
              },
            ]
          : []),
        {
          name: 'Products',
          layout: '/admin',
          path: '/viewproducts',
          icon: (
            <Icon
              as={MdVisibility}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: 'Enable Product',
          layout: '/admin',
          path: '/enableproduct',
          icon: (
            <Icon
              as={MdVisibility}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
        {
          name: 'Disable Product',
          layout: '/admin',
          path: '/disableproduct',
          icon: (
            <Icon as={MdBlock} width="20px" height="20px" color="inherit" />
          ),
        },
      ],
    },
    // Reservations
    {
      name: 'Reservations',
      layout: '/admin',
      icon: <Icon as={MdEvent} width="20px" height="20px" color="inherit" />,
      subroutes: [
        ...(userRole === 'Pm'
          ? [
              {
                name: 'Add Reservation',
                layout: '/admin',
                path: '/addreserve',
                icon: (
                  <Icon as={MdAdd} width="20px" height="20px" color="inherit" />
                ),
              },
            ]
          : []),
        {
          name: 'View Reservations',
          layout: '/admin',
          path: '/viewreservation',
          icon: (
            <Icon
              as={MdVisibility}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
        },
      ],
    },
    // Change Password
    {
      name: 'Change Password',
      layout: '/admin',
      path: '/changepassword',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    },
    // Commission

    ...(userRole === 'Admin' || userRole === 'SuperAdmin'
      ? [
          {
            name: 'View Commission',
            layout: '/admin',
            path: '/viewcommission',
            icon: (
              <Icon as={MdMoney} width="20px" height="20px" color="inherit" />
            ),
          },
        ]
      : []),
    // Change User Password

    ...(userRole === 'Admin' || userRole === 'SuperAdmin'
      ? [
          {
            name: 'Update Passwords',
            layout: '/admin',
            path: '/changeuserpassword',
            icon: (
              <Icon
                as={MdPassword}
                width="20px"
                height="20px"
                color="inherit"
              />
            ),
          },
        ]
      : []),

    // History
    {
      name: 'History',
      layout: '/admin',
      path: '/history',
      icon: <Icon as={FaHistory} width="20px" height="20px" color="inherit" />,
    },
    // Profile
    {
      name: 'User Profile',
      layout: '/admin',
      path: '/profile',

      icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    },
    // Auth Routes
    {
      name: 'Logout',
      layout: '',
      path: '/logout',
      // Use Chakra's color system for red
      icon: <Icon as={MdLogout} width="20px" height="20px" color="red.500" />,
    },
  ];
  return routes;
}
function Sidebar() {
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, []);

  const routes = getRoutes(userRole);

  let variantChange = '0.2s linear';
  let shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
  );

  let sidebarBg = useColorModeValue('white', 'navy.800');
  let sidebarMargins = '0px';

  // SIDEBAR
  // Inside Sidebar component return statement
  return (
    <Box
      display={{ sm: 'none', xl: 'block' }}
      w="100%"
      position="fixed"
      minH="100%"
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="300px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}
        >
          <Flex
            direction="column"
            height="100%"
            pt="1px"
            px="5px"
            borderRadius="30px"
          >
            <Brand />
            <Stack direction="column" mb="auto" mt="8px">
              <Box ps="1px" pe={{ md: '16px', '2xl': '1px' }}></Box>

              <SidebarLinks routes={routes} />
            </Stack>
            <Box mt="60px" mb="40px" borderRadius="30px">
              <SidebarCard />
            </Box>
          </Flex>
        </Scrollbars>
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
  }, []);

  const routes = getRoutes(userRole);
  const sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const menuColor = useColorModeValue('gray.400', 'white');

  return (
    <Flex display={{ sm: 'flex', xl: 'none' }} alignItems="center">
      <Flex ref={btnRef} w="max-content" h="max-content" onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={menuColor}
          my="auto"
          w="20px"
          h="20px"
          me="10px"
          _hover={{ cursor: 'pointer' }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
          <Brand />
          <DrawerCloseButton
            zIndex="3"
            onClose={onClose}
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW="285px" px="0rem" pb="0">
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}
            >
              <Flex
                direction="column"
                height="100%"
                px="5px"
                borderRadius="30px"
              >
                <Stack direction="column" mb="auto">
                  <Box ps="1px" pe={{ md: '16px', '2xl': '1px' }}></Box>
                  <SidebarLinks routes={routes} onSelect={onClose} />
                </Stack>
                <Box mt="60px" mb="40px" borderRadius="30px">
                  <SidebarCard />
                </Box>
              </Flex>
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
  // onSelect: PropTypes.func.isRequired,
};

export default Sidebar;
