import { Button, Flex, Link } from '@chakra-ui/react';
import React from 'react';
import Lock from '../components/Lock';

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    width="20px"
    height="20px"
  >
    <path
      fill="#25D366"
      d="M12 0C5.373 0 0 5.373 0 12c0 2.174.578 4.25 1.605 6.063L0 24l5.437-1.605C7.75 23.422 9.828 24 12 24c6.627 0 12-5.373 12-12S18.627 0 12 0zm6.197 17.328c-.378.7-1.1 1.173-1.934 1.173-1.278 0-3.194-1.227-4.578-2.25l-.048-.035c-.062-.046-1.457-1.1-2.195-1.15-1.084-.068-2.283-.697-2.983-1.733-.396-.564-1.42-2.212-1.42-4.265 0-1.514.557-2.75 1.36-3.916.812-1.163 1.948-2.176 3.126-2.21 1.528-.038 2.952.462 4.09 1.03 1.438.687 3.148 1.305 4.012 2.51.472.675.86 1.486.86 2.42 0 1.43-.493 2.912-1.118 3.682-.063.074-.111.185-.167.277l-.004.006c-.011.017-.019.035-.026.052-.08.135-.166.265-.263.39z"
    />
  </svg>
);

export default function SidebarDocs() {
  return (
    <Flex
      justify="center"
      direction="column"
      align="center"
      borderRadius="30px"
      position="relative"
      p={4} // Add padding
    >
      <Lock />
      <Link href="https://wa.me/923047662828" isExternal>
        <Button
          border="2px" // Add border width
          borderColor="green.500" // Set border color to green
          leftIcon={<WhatsAppIcon />} // Add the WhatsApp icon
          bg="whiteAlpha.300"
          _hover={{ bg: 'whiteAlpha.200' }}
          _active={{ bg: 'whiteAlpha.100' }}
          mb={{ sm: '16px', xl: '24px' }}
          color={'green.500'}
          fontWeight="regular"
          fontSize="sm"
          minW="185px"
          mx="auto"
        >
          Join WhatsApp Group
        </Button>
      </Link>
    </Flex>
  );
}
