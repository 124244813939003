// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React, { useRef } from 'react';

export default function Banner(props) {
  const { banner, avatar, name, job, posts, followers, following, userId } =
    props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );

  const fileInputRef = useRef(null); // Create a ref for the file input

  const handleAvatarClick = () => {
    fileInputRef.current.click(); // Trigger click on the hidden file input
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Handle the file upload logic here
      // // console.log('File selected:', file);
      // Add your upload logic here
    }
  };

  return (
    <Card mb={{ base: '0px', lg: '20px' }} align="center" height="300px">
      <Box
        bg={`url(${banner})`}
        bgSize="cover"
        borderRadius="16px"
        h="131px"
        w="100%"
      />
      <Avatar
        mx="auto"
        src={avatar}
        h="87px"
        w="87px"
        mt="-43px"
        border="4px solid"
        borderColor={borderColor}
        onClick={handleAvatarClick} // Add click event to the avatar
      />
      <input
        type="file"
        ref={fileInputRef} // Attach the ref
        onChange={handleFileChange} // Handle file selection
        style={{ display: 'none' }} // Hide the file input
      />
      <Text color={textColorPrimary} fontWeight="bold" fontSize="xl" mt="10px">
        {name}
      </Text>
      <Text
        color={textColorPrimary}
        fontSize="lg"
        fontWeight="bold"
        letterSpacing="0.5px"
      >
        {job}
        <Text
          as="span"
          color={textColorSecondary}
          fontSize="md"
          fontWeight="normal"
          mx={1} // Adds margin left and right for spacing around the parentheses
        >
          ( {userId} )
        </Text>
      </Text>

      <Flex w="max-content" mx="auto" mt="26px">
        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {followers}
          </Text>
          {/* local storage ma ager localStorageRole Pm ho then show   */}
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Followers
          </Text>
        </Flex>

        <Flex mx="auto" me="60px" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Logged In
          </Text>
        </Flex>

        <Flex mx="auto" align="center" direction="column">
          <Text color={textColorPrimary} fontSize="2xl" fontWeight="700">
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize="sm" fontWeight="400">
            Requests
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
