import {
  Box,
  Text,
  Avatar,
  useColorModeValue,
  keyframes,
  Button,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React, { useRef, useState } from 'react';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient'; // Import your API client

export default function Banner(props) {
  const { avatar, name, OrderId } = props; // Make sure OrderId is passed as a prop
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(avatar); // Single state for image URL
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isImageUploaded, setIsImageUploaded] = useState(false); // Track if image is uploaded and ready for confirmation

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue(
    'white !important',
    '#111C44 !important',
  );

  const loadingAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.5); }
    100% { transform: scale(1); }
  `;

  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setError('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('key', '0c7a84592216317dc484e9758f6d7a23'); // ImgBB API key
    formData.append('image', selectedFile);

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        'https://api.imgbb.com/1/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const displayUrl = response.data.data.display_url;
      setImageUrl(displayUrl); // Store the image URL in the state
      setError(''); // Clear error
      setIsImageUploaded(true); // Set flag for confirmation UI
    } catch (error) {
      setError(`Error uploading image. Please try again.`);
      setSuccess(''); // Clear success message
    } finally {
      setLoading(false); // Ensure loading state is reset
    }
  };

  const handleSubmit = async () => {
    setError('');
    setSuccess('');

    // Construct payload with both the image URL and file name
    const payload = {
      imageUrl, // Pass the uploaded image URL
      name, // Pass the file name
    };

    try {
      const response = await apiClient.put(`updatepics/${OrderId}`, payload);
      setSuccess('File update successfully!');
      setIsImageUploaded(false); // Reset the uploaded flag
    } catch (error) {
      setError('An error occurred while processing your request.');
    }
  };

  const handleCancel = () => {
    // Revert the image to the original avatar and reset the upload state
    setImageUrl(avatar);
    setIsImageUploaded(false);
  };

  // Render error or success messages
  const renderMessages = () => {
    if (errorMsg) {
      return <Text color="red.500">{errorMsg}</Text>;
    }
    if (success) {
      return <Text color="green.500">{success}</Text>;
    }
    return null;
  };

  // If loading, show the loading overlay and animation
  if (loading) {
    return (
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.8)"
        zIndex="999"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          {['red.500', 'blue.500', 'green.500', 'yellow.500', 'purple.500'].map(
            (color, index) => (
              <Box
                key={index}
                bg={color}
                borderRadius="50%"
                width="15px"
                height="15px"
                marginX="5px"
                animation={`${loadingAnimation} 1s infinite ease-in-out`}
                animationDelay={`${index * 0.2}s`}
              />
            ),
          )}
        </Box>
        <Text
          fontSize="3xl"
          color="white"
          mt={1}
          fontWeight="bold"
          textAlign="center"
          textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
          letterSpacing="1px"
        >
          File Uploading...
        </Text>
      </Box>
    );
  }

  return (
    <Card
      mb={{ base: '0px', lg: '20px' }}
      align="center"
      height="100%"
      width="100%" // Set the card width to 100%
      p="20px" // Add padding to ensure text doesn't touch the edges
    >
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        mt="10px"
        textAlign="center"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {name}
      </Text>

      <Avatar
        mx="auto"
        src={imageUrl} // Use the dynamically set imageUrl
        h="350px"
        w="250px"
        mt="20px"
        borderRadius="0"
        border="4px solid"
        borderColor={borderColor}
        objectFit="contain"
        onClick={handleAvatarClick}
      />

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload} // Single handler for file upload
        style={{ display: 'none' }}
      />

      {isImageUploaded && (
        <Box mt={4} textAlign="center">
          <Button colorScheme="green" onClick={handleSubmit} mr={4}>
            Save
          </Button>
          <Button colorScheme="red" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      )}

      {renderMessages()}
    </Card>
  );
}
