// Chakra imports
import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
// Custom icons
import React from 'react';

export default function Default(props) {
  const { startContent, endContent, name, growth, value } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';

  // Define custom box shadow
  const customShadow =
    '0 2px 0 rgba(90, 97, 105, .11), 0 4px 8px rgba(90, 97, 105, .12), 0 10px 10px rgba(90, 97, 105, .06), 0 7px 70px rgba(90, 97, 105, .1)';

  return (
    <Card boxShadow={customShadow}>
      {' '}
      {/* Apply custom shadow here */}
      <Flex
        my="auto"
        h="80px"
        align="center" // Vertical alignment
        justify="center" // Horizontal alignment
      >
        <Flex
          align="center"
          justify="center"
          mr={startContent ? '18px' : '0px'}
        >
          {startContent}
        </Flex>

        <Stat my="auto" textAlign="center">
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{
              base: 'md', // Increased size for base
            }}
          >
            {name}
          </StatLabel>
          <StatNumber
            color={textColor}
            fontSize={{
              base: '3xl', // Increased size for base
            }}
          >
            {value}
          </StatNumber>
          {growth ? (
            <Flex align="center" justify="center">
              <Text
                color={growth > 0 ? 'green.600' : 'blue.500'}
                fontSize="sm"
                fontWeight="700"
                me="5px"
              >
                {growth}
              </Text>

              <Text color="secondaryGray.600" fontSize="sm" fontWeight="400">
                since month
              </Text>
            </Flex>
          ) : null}
        </Stat>

        <Flex ms="auto" w="max-content" align="center" justify="center">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}
