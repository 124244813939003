import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { FaWhatsapp } from 'react-icons/fa';
import { MdCancel, MdCheckCircle, MdWarning } from 'react-icons/md';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import apiClient from '../../../../authclient/authclient';

const columnHelper = createColumnHelper();

const STATUS_MAP = {
  blocked: { color: 'red.500', icon: MdCancel, label: 'Blocked' },
  warned: { color: 'yellow.500', icon: MdWarning, label: 'Warned' },
  active: { color: 'green.500', icon: MdCheckCircle, label: 'Active' },
};
const getStatusColor = (status) => {
  switch (status) {
    case 'blocked':
      return 'green.500'; // Green for blocked (maybe it's unblocking or confirming unblock)
    case 'warned':
      return 'red.500'; // Red for warned (indicating an alert)
    case 'active':
      return 'yellow.500'; // Yellow for active (warning state)
    default:
      return 'gray.500'; // Default color if status is unknown
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 'blocked':
      return 'Unblock'; // If blocked, the label says 'Unblock'
    case 'warned':
      return 'Block'; // If warned, the label says 'Block'
    case 'active':
      return 'Warned'; // If active, the label says 'Warned'
    default:
      return 'Unknown'; // Default label for unknown status
  }
};

export default function ComplexTable({ tableData, toggleEnableFunction }) {
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);
  const viewProfilehandler = (userViewId) => {
    // console.log(userViewId, 'view function called');
    localStorage.setItem('userViewId', userViewId);
    navigate(`/admin/usersprofileinfo`);
  };

  const columns = [
    columnHelper.accessor('phone', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Seller
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const phoneNumber =
                info.row.original.sellerPhoneNumber || '1234567890'; // Dynamically set seller's phone number
              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* Seller Name or Info */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || '295'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('email', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" textAlign="center">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('userName', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          User Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" textAlign="center">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue() || 'unknown';
        const { color, icon, label } = STATUS_MAP[status] || STATUS_MAP.unknown;
        return (
          <Flex align="center" justify="center">
            <Icon w="24px" h="24px" me="5px" color={color} as={icon} />
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {label}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('role', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Role
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" textAlign="center">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('createdBy', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Created By
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" textAlign="center">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('createdAt', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Created At
        </Text>
      ),
      cell: (info) => {
        const createdAt = info.getValue();
        const formattedCreatedAt = createdAt
          ? format(new Date(createdAt), 'MM/dd/yyyy HH:mm:ss') // Format as MM/dd/yyyy HH:mm:ss
          : ''; // If no date, display empty string

        return (
          <Text color={textColor} fontSize="lg" textAlign="center">
            {formattedCreatedAt}
          </Text>
        );
      },
    }),
    columnHelper.accessor('action', {
      header: () => (
        <Text fontSize="12px" color="gray.400">
          Actions
        </Text>
      ),
      cell: (info) => (
        <Box display="flex" gap="8px" justifyContent="center">
          <Button
            color={getStatusColor(info.row.original.status)}
            borderColor={getStatusColor(info.row.original.status)}
            fontSize="lg"
            variant="outline"
            borderRadius="full"
            padding="8px 12px"
            width="100px"
            onClick={() => handleActionClick(info.row.original._id)}
          >
            {getStatusLabel(info.row.original.status)}
          </Button>
          {userRole === 'SuperAdmin' && (
            <Button
              color="white"
              bg="red.500"
              variant="outline"
              onClick={() => handleDeleteClick(info.row.original._id)}
              borderRadius="full"
              padding="8px 12px"
              _hover={{ bg: 'red.600' }}
            >
              Delete
            </Button>
          )}
          <Button
            color="white"
            bg="#3965ff"
            variant="outline"
            onClick={() => viewProfilehandler(info.row.original._id)}
            borderRadius="full"
          >
            View
          </Button>
        </Box>
      ),
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleActionClick = async (userId) => {
    if (!userId) {
      //console.error('User ID is missing');
      alert('User ID is missing');
      return;
    }

    try {
      const response = await apiClient.put('updateUserStatus/', { userId });
      const { message, user } = response.data;
      Swal.fire({
        title: 'Success!',
        text: `User status updated to ${user.status}`,
        icon: 'success',
        confirmButtonColor: '#3085d6', // You can customize the button color
      });
    } catch (error) {
      //console.error('Error updating user status:', error);
      Swal.fire({
        title: 'Error!',
        text: 'An error occurred while updating the user status. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33', // Red color for the error button
      });
    }
    toggleEnableFunction();
  };

  const handleDeleteClick = async (userId) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (!isConfirmed) return;

    try {
      const response = await apiClient.delete(`deleteUser/${userId}`);
      Swal.fire('Deleted!', 'User has been deleted.', 'success');
      if (response.status === 200) {
        // Optionally refresh table data
      }
    } catch (error) {
      Swal.fire('Error!', 'Could not delete the user.', 'error');
      //console.error('Error deleting user:', error);
    }
    toggleEnableFunction();
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      borderTopLeftRadius="0px"
      borderTopRightRadius="0px"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="center"
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize="12px"
                      color="gray.400"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      <Box ml={2}>
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === 'asc'
                            ? '↑'
                            : '↓'
                          : null}
                      </Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={`${cell.id}_${row.id}`}
                    borderColor="transparent"
                    textAlign="center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
