import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link as RouterLink } from 'react-router-dom';
import Card from 'components/card/Card';
import { format } from 'date-fns';
const handleWhatsAppClick = (sellerName) => {
  const phoneNumber = '1234567890'; // Replace with the actual phone number
  const message = `Hello ${sellerName}, I would like to inquire about your product.`;
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message,
  )}`;
  window.open(url, '_blank');
};

const columnHelper = createColumnHelper();

export default function ComplexTable({ tableData }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = [
    // columnHelper.accessor('seller', {
    //   header: () => (
    //     <Text
    //       fontSize={{ sm: '10px', lg: '12px' }}
    //       color="gray.400"
    //       textAlign="center"
    //     >
    //       #
    //     </Text>
    //   ),
    //   cell: (info) => (
    //     <Flex align="center" justify="center">
    //       <Icon
    //         as={FaWhatsapp}
    //         w={8}
    //         h={8}
    //         color="green.500"
    //         cursor="pointer"
    //         onClick={() => handleWhatsAppClick(info.getValue())} // Click handler for WhatsApp icon
    //       />
    //     </Flex>
    //   ),
    // }),

    columnHelper.accessor('user', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Users Id
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '01'}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || ''}
        </Text>
      ),
    }),
    columnHelper.accessor('role', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          UserRole
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'Nabeel Ijaz'}
        </Text>
      ),
    }),
    columnHelper.accessor('type', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Action Type
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || ''}
        </Text>
      ),
    }),
    columnHelper.accessor('actionTimestamp', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Action Time
        </Text>
      ),
      cell: (info) => {
        const actionTimestamp = info.getValue();
        const formattedTimestamp = actionTimestamp
          ? format(new Date(actionTimestamp), 'MM/dd/yyyy HH:mm:ss') // Format timestamp to date + time
          : ''; // Return empty string if no timestamp is provided

        return (
          <Text color={textColor} fontSize="lg" fontWeight="200">
            {formattedTimestamp}
          </Text>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      borderTopLeftRadius="0px"
      borderTopRightRadius="0px"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    borderColor={borderColor}
                    textAlign="center"
                  >
                    <Flex justifyContent="center" align="center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    borderColor="transparent"
                    textAlign="center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
