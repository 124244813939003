import React from 'react';

// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card.js';

export default function DailyTraffic({ data, ...rest }) {
  // Prepare the chart data using the passed data prop
  const barChartDataDailyTraffic = [
    {
      name: 'Weekly Traffic',
      data: data || [0, 0, 0, 0, 0, 0, 0], // Default data if none is provided
    },
  ];

  const barChartOptionsDailyTraffic = {
    chart: {
      toolbar: { show: false },
    },
    tooltip: {
      style: { fontSize: '12px', fontFamily: undefined },
      onDatasetHover: { style: { fontSize: '12px', fontFamily: undefined } },
      theme: 'dark',
    },
    xaxis: {
      categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#A3AED0',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      show: false,
      labels: {
        show: true,
        style: {
          colors: '#CBD5E0',
          fontSize: '14px',
        },
      },
    },
    grid: {
      show: false,
      yaxis: { lines: { show: true } },
      xaxis: { lines: { show: false } },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          { offset: 0, color: '#4318FF', opacity: 1 },
          { offset: 100, color: 'rgba(67, 24, 255, 1)', opacity: 0.28 },
        ],
      },
    },
    dataLabels: { enabled: false },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '40px',
      },
    },
  };

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Card align="center" direction="column" h="100%" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Weekly Traffic
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              color={textColor}
              fontSize="34px"
              fontWeight="700"
              lineHeight="100%"
              mt="10px"
            >
              {data ? data.reduce((a, b) => a + b, 0) : 0}{' '}
              {/* Display total visitors */}
            </Text>
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
            >
              Visitors
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box h="240px" mt="auto">
        <BarChart
          chartData={barChartDataDailyTraffic}
          chartOptions={barChartOptionsDailyTraffic}
        />
      </Box>
    </Card>
  );
}
