import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Text,
  Skeleton,
  Button,
  Flex,
  Select,
  keyframes,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ComplexTable from "views/admin/reservations/components/ViewReservation";
import apiClient from "../../../authclient/authclient";
import { useNavigate, useLocation } from 'react-router-dom';

const shadowAnimation = keyframes`
  0% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
  50% { box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
  100% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
`;

export default function UserReports() {
  const navigate = useNavigate();
  const brandColor = useColorModeValue("white","#111c44" );
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
    
    const response = await apiClient.get(`reservations`, {

        params: {
          page: pageIndex + 1,
          size: pageSize,
        },
      });
 
      // console.log(response);
      if (response.data) {
     
     setTableData(response.data.reservations);
     setTotalPages(response.data.totalPages);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        navigate('/login'); // Redirect to login if 403 Forbidden error occurs
      }
      //console.error("Error fetching data:", err);
     
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize
  ]);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={1} spacing={4}>
          {[...Array(7)].map((_, index) => (
            <Box key={index} height="50px" borderRadius="10px" animation={`${shadowAnimation} 1.5s infinite`} overflow="hidden">
              <Skeleton height="100%" borderRadius="10px" fadeDuration={1} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} textAlign="center">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Flex
          px="25px"
         bg= {brandColor}// White in light mode, dark gray in dark mode
          justifyContent="space-between"
          align="center"
          gap={2}
          borderTopLeftRadius="15px"
          borderTopRightRadius="15px"
        >
          <Text fontSize="22px" fontWeight="200" mt="15px">
            Overview Reservations
          </Text>
       
        </Flex>
 
        <ComplexTable tableData={tableData} toggleEnableFunction={fetchData}  pageSize={pageSize} />

        {totalPages > 1  && (
  <Flex justifyContent="space-between" align="center" p="15px"
  >
    <Button
      onClick={() => setPageIndex((old) => Math.max(0, old - 1))}
      disabled={pageIndex === 0}
    >
      Previous
    </Button>
    <Text>
      Page {pageIndex + 1} of {totalPages}
    </Text>
    <Button
      onClick={() => setPageIndex((old) => Math.min(totalPages - 1, old + 1))}
      disabled={pageIndex >= totalPages - 1}
    >
      Next
    </Button>
  </Flex>
)}
      </SimpleGrid>
    </Box>
  );
}
