import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { motion } from 'framer-motion';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient';
const columnHelper = createColumnHelper();

export default function ComplexTable({ tableData, toggleEnableFunction }) {
  const navigate = useNavigate();
  const [sorting, setSorting] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);
  const getStatusColor = (status) => {
    switch (status) {
      case 'disable':
        return 'green.500';
      case 'enable':
        return 'red.500';
      default:
        return 'gray.500';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'disable':
        return 'Enable';
      case 'enable':
        return 'Disable';
      default:
        return 'Unknown';
    }
  };

  const handleReserveClick = async (productId) => {
    const userId = localStorage.getItem('userId'); // Get the userId from localStorage
    if (!userId) {
      // If user is not authenticated, navigate to the login page
      navigate('/login');
      return;
    }

    if (!productId) {
      // If productId is missing, display an error message
      //console.error('Product ID is missing');
      alert('Product ID is missing');
      return;
    }

    try {
      // Make the API call to create a reservation
      const response = await apiClient.post('createReservation', {
        productId,
        userId,
      });

      // Handle successful reservation
      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Your reservation has been successfully confirmed. Thank you for choosing our service!',
          confirmButtonText: 'OK',
        });
      } else {
        // If the status is not 201, show an error message based on the backend response

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text:
            response.data.message ||
            'An error occurred while submitting your reservation.',
          confirmButtonText: 'Try Again',
        });
      }
    } catch (error) {
      // Handle errors that occur during the request (e.g., product out of stock, daily limit exceeded)
      //console.error('Error submitting reservation:', error);

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text:
          error.response?.data?.message ||
          'An error occurred while submitting your reservation. Please try again.',
        confirmButtonText: 'Try Again',
      });
    }

    // Optionally, toggle UI elements like buttons or loading indicators
    toggleEnableFunction();
  };

  const handleDeleteClick = async (productId) => {
    const { isConfirmed } = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    });

    if (!isConfirmed) return;

    try {
      const response = await apiClient.delete(`deleteProduct/${productId}`);
      Swal.fire('Deleted!', 'Product has been deleted.', 'success');

      if (response.status === 200) {
        // Optionally refresh table data or update UI
      }
    } catch (error) {
      Swal.fire('Error!', 'Could not delete the product.', 'error');
      //console.error('Error deleting product:', error);
    }

    toggleEnableFunction();
  };

  const viewProductHandler = (productId) => {
    localStorage.setItem('ViewproductId', productId);
    navigate(`/admin/productinfo`);
  };

  const columns = [
    columnHelper.accessor('PhoneNumber', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Seller
        </Text>
      ),
      cell: (info) => (
        <Flex direction="column" align="center" justify="center">
          {/* WhatsApp Icon */}
          <Icon
            as={FaWhatsapp}
            w={8}
            h={8}
            color="green.500"
            cursor="pointer"
            onClick={() => {
              const message = `Hello, I'm interested in the product ${info.getValue()}`; // Example message
              const phoneNumber =
                info.row.original.sellerPhoneNumber || '1234567890'; // Dynamically set seller's phone number
              const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
                message,
              )}`;
              window.open(url, '_blank'); // Open the link in a new tab
            }}
          />
          {/* Seller Name or Info */}
          <Text fontSize="sm" mt={2}>
            {info.getValue() || ''}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('market', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Market
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor('saleLimitPerDay', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Sale Limit
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '0'}
        </Text>
      ),
    }),
    columnHelper.accessor('remaningSaleLimit', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Today Remaining
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '-'}
        </Text>
      ),
    }),
    columnHelper.accessor('overAllSaleLimit', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Total Remaining
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '0'}
        </Text>
      ),
    }),
    columnHelper.accessor('adverzProCommission', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Commission
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || '700'}
        </Text>
      ),
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Product ID
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue() || 'N/A'}
        </Text>
      ),
    }),
    columnHelper.accessor('image1Url', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Image
        </Text>
      ),
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Product"
          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
          onClick={() => {
            setSelectedImage(info.getValue());
            setIsOpen(true);
          }}
        />
      ),
    }),
    columnHelper.accessor('view', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Action Buttons
        </Text>
      ),
      cell: (info) => {
        const isEnabled = info.row.original.istatus;

        return (
          <HStack spacing={2} justify="center">
            {userRole !== 'Pm' && (
              <Button
                colorScheme="gray" // Sets a default color scheme
                textColor={getStatusColor(info.row.original.istatus)} // Text color
                borderColor={getStatusColor(info.row.original.istatus)} // Border color
                fontSize="lg"
                variant="outline"
                borderRadius="full"
                padding="8px 12px"
                width="100px"
                onClick={() => handleStatusConfirmation(info.row.original._id)}
              >
                {getStatusLabel(info.row.original.istatus)}
              </Button>
            )}
            {userRole === 'Pm' && (
              <Button
                color="white"
                borderColor="white"
                bg={
                  info.row.original.istatus === 'disable'
                    ? 'red.500'
                    : 'green.500'
                }
                borderRadius="full"
                _hover={{
                  bg:
                    info.row.original.istatus === 'disable'
                      ? 'red.500'
                      : 'green.600',
                }}
                isDisabled={
                  info.row.original.istatus === 'disable' ||
                  info.row.original.remaningSaleLimit === 0
                }
                onClick={() => {
                  // This will only be triggered if the button is enabled
                  if (
                    info.row.original.istatus !== 'disable' &&
                    info.row.original.remaningSaleLimit > 0
                  ) {
                    handleReserveClick(info.row.original._id);
                  }
                }}
              >
                {info.row.original.istatus === 'disable' ||
                info.row.original.remaningSaleLimit === 0
                  ? 'Out of Stock'
                  : 'Reserve Now'}
              </Button>
            )}
            {userRole === 'SupperAdmin' && (
              <Button
                color="white"
                bg="red.500"
                variant="outline"
                onClick={() => handleDeleteClick(info.row.original._id)}
                borderRadius="full"
                padding="8px 12px"
                _hover={{ bg: 'red.600' }}
              >
                Delete
              </Button>
            )}
            <Button
              color="white"
              bg="#3965ff"
              variant="outline"
              onClick={() => viewProductHandler(info.row.original._id)}
              borderRadius="full"
            >
              View
            </Button>
          </HStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleStatusConfirmation = async (productId) => {
    Swal.fire({
      title: `Are you sure?`,
      text: `You won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await apiClient.put('updateProductStatus', {
            productId,
          });
          const { message, product } = response.data;
          // Use Swal for error message
          Swal.fire({
            title: 'Success!',
            text: message,
            icon: 'success',
            confirmButtonColor: '#3085d6',
          });
        } catch (error) {
          //console.error('Error updating product status:', error);
          // Use Swal for error message
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred while updating the product status. Please try again.',
            icon: 'error',
            confirmButtonColor: '#d33',
          });
        }
        toggleEnableFunction();
      }
    });
  };

  return (
    <Card
      flexDirection="column"
      w="100%"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      borderTopLeftRadius="0"
      borderTopRightRadius="0"
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="center"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Box ml={2}>
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === 'asc'
                            ? '↑'
                            : '↓'
                          : null}
                      </Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={`${cell.id}_${row.id}`}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '10px', md: '20px', lg: 'auto' }}
                    borderColor="transparent"
                    textAlign="center"
                    verticalAlign="middle"
                    py={4} // Add padding top and bottom for spacing
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {cell.column.columnDef.cell === 'image' ? (
                        <img
                          src={cell.getValue()}
                          alt="Image"
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            margin: '0 auto', // Center the image
                            padding: '4px', // Add some padding around the image
                          }}
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )
                      )}
                    </div>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={{ base: 'md', sm: 'lg' }}
        isCentered
      >
        <ModalOverlay />
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.3 }}
        >
          <ModalContent
            borderRadius="md"
            boxShadow="lg"
            p={2}
            mt={{ base: 'center', sm: '140' }}
          >
            <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
              Image Preview
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody
              display="flex"
              justifyContent="center"
              alignItems="center"
              pb={6}
            >
              <Image
                src={selectedImage}
                alt="Enlarged Product"
                maxW="100%"
                maxH="80vh"
                objectFit="contain"
                borderRadius="md"
              />
            </ModalBody>
          </ModalContent>
        </motion.div>
      </Modal>
    </Card>
  );
}
