import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Text } from '@chakra-ui/react';
import apiClient from '../../../../authclient/authclient';

const delt_reservation = async (id) => {
  try {
    const response = await apiClient.delete(`delt_reservations/${id}`);
    console.log(response.data.message); // Log success message
  } catch (error) {
    console.error(
      'Error deleting reservation:',
      error.response?.data?.message || error.message,
    );
  }
};

const CountdownCell = ({ createdAt, id, toggleEnableFunction }) => {
  const [remainingTime, setRemainingTime] = useState('00:00:00');
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const currentTime = moment.tz('Asia/Karachi');
      const createdAtTime = moment.utc(createdAt).tz('Asia/Karachi'); // Use UTC as base

      const diff = currentTime.diff(createdAtTime);
      const twoHoursInMillis = 2 * 60 * 60 * 1000;

      if (diff >= twoHoursInMillis) {
        setRemainingTime('Expired');
        if (!isExpired) {
          toggleEnableFunction();
          delt_reservation(id);
          setIsExpired(true);
        }
        return;
      }

      const remainingDiff = twoHoursInMillis - diff;
      const duration = moment.duration(remainingDiff);

      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');

      setRemainingTime(`${hours}:${minutes}:${seconds}`);
    };

    calculateRemainingTime();
    const interval = setInterval(calculateRemainingTime, 1000);

    return () => clearInterval(interval);
  }, [createdAt, id, isExpired, toggleEnableFunction]);

  return (
    <Text
      color={isExpired ? '#ff0000' : '#00ff00'}
      fontSize="lg"
      fontWeight="200"
      fontFamily="'Share Tech Mono', monospace"
      textAlign="center"
      textShadow="0 0 20px rgba(210, 1, 2, 1), 0 0 20px rgba(210, 1, 2, 0)"
    >
      {remainingTime}
    </Text>
  );
};

export default CountdownCell;
