// Chakra imports

import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Projects from "views/admin/orders/components/AddOrder";

// Assets
import React from "react";
// // console.log('Add order page');
export default function Overview() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>  
      <Grid
  mb='20px'
  templateColumns={{
    base: "1fr",
    lg: "repeat(1, 1fr)", // 2 columns for large screens
    "2xl": "1.34fr 1.62fr 1fr", // 3 columns for extra large screens
  }}
  templateRows={{
    base: "1fr", // 1 row for mobile
    lg: "1fr", // 1 row for large screens
    "2xl": "1fr", // 1 row for extra large screens
  }}
  
  gap={{ base: "20px", xl: "20px" }}
>
        <Projects/>

      </Grid>
    </Box>
  );
}
