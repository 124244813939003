// Chakra imports
import {
  Text,
  useColorModeValue,
  Button,
  FormControl,
  Input,
  Flex,
  keyframes,
  FormLabel,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Card from 'components/card/Card.js';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient';
import Swal from 'sweetalert2';

export default function Projects() {
  const location = useLocation();
  const { productId } = location.state || {}; // Access productId from location state

  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);

  if (userRole !== 'Pm') {
    navigate('/admin/dashboard');
  }

  const loadingAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.5); }
    100% { transform: scale(1); }
  `;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  // State variables for form data and feedback
  const [orderNo, setOrderNo] = useState('');
  const [customerEmailAddress, setCustomerEmailAddress] = useState('');
  const [image1Url, setImage1Url] = useState(null);
  const [image2Url, setImage2Url] = useState(null); // Adjusted from your original 'image3Url'
  const [amzReviewLink, setAmzReviewLink] = useState('');
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const textColor = useColorModeValue('black', 'white');

  // Unified function to handle image upload for both images
  const handleFileUpload = async (event, type) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setError('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('key', '0c7a84592216317dc484e9758f6d7a23'); // Replace with your ImgBB API key
    formData.append('image', selectedFile);

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        'https://api.imgbb.com/1/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const displayUrl = response.data.data.display_url;
      switch (type) {
        case 'OrderPic':
          setImage1Url(displayUrl);
          break;
        case 'Refundpic':
          setImage2Url(displayUrl);
          break;
        default:
          setError('Unknown upload type.');
      }
    } catch (error) {
      setError(`Error uploading ${type}. Please try again.`);
      setSuccess('');
    } finally {
      setLoading(false); // Ensure loading is reset
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (!orderNo || !customerEmailAddress) {
      setError('All required fields must be filled in.');
      return;
    }

    const userId = localStorage.getItem('userId');
    const reservationId = localStorage.getItem('reservationViewId');

    if (!userId) {
      navigate('/login');
      return;
    }

    const payload = {
      orderNo,
      customerEmailAddress,
      image2Url, // Refund picture URL
      amzReviewLink,
      image1Url, // Order picture URL
      userId,
      reservationId, // Include productId from location state
    };

    try {
      const response = await apiClient.post('createOrder', payload);
      // console.log(response);
      setSuccess(
        'Your order has been successfully submitted. Thank you for choosing our service!',
      );
      Swal.fire({
        title: 'Success!',
        text: 'Your order has been successfully submitted. Thank you for choosing our service!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
      navigate('/admin/vieworders');
    } catch (error) {
      if (error.response && error.response.status === 403) {
        // Redirect to login if 403 Forbidden error occurs
        navigate('/login');
      } else if (error.response) {
        // Display error message from the server
        setError(
          error.response.data.message ||
            'An error occurred while processing your request.',
        );
      } else if (error.request) {
        // No response from the server
        setError('No response from server.');
      } else {
        // Handle any other general error
        setError('An error occurred while processing your request.');
      }

      // Display SweetAlert with the error message
      Swal.fire({
        title: 'Error!',
        text: errorMsg || 'An unexpected error occurred. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <Card>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="0px"
        mb="25px"
      >
        Add New Order
      </Text>

      <form onSubmit={handleSubmit}>
        <FormControl>
          {loading && (
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="rgba(0, 0, 0, 0.8)"
              zIndex="999"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {[
                  'red.500',
                  'blue.500',
                  'green.500',
                  'yellow.500',
                  'purple.500',
                ].map((color, index) => (
                  <Box
                    key={index}
                    bg={color}
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    marginX="5px"
                    animation={`${loadingAnimation} 1s infinite ease-in-out`}
                    animationdelay={`${index * 0.2}s`}
                  />
                ))}
              </Box>
              <Text
                fontSize="3xl"
                color="white"
                mt={1}
                fontWeight="bold"
                textAlign="center"
                textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
                letterSpacing="1px"
              >
                File Uploading...
              </Text>
            </Box>
          )}

          <Flex direction={{ base: 'column', md: 'row' }} mb="24px">
            <Box flex="1" pr={{ md: '12px' }}>
              <Input
                color={textColor}
                type="text"
                name="orderNo"
                value={orderNo}
                onChange={(e) => setOrderNo(e.target.value)}
                placeholder="Order Number"
                mb="5"
                required
              />
              <Input
                color={textColor}
                type="email"
                name="customerEmailAddress"
                value={customerEmailAddress}
                onChange={(e) => setCustomerEmailAddress(e.target.value)}
                placeholder="Customer Email Address"
                mb="5"
                required
              />
              <Input type="text" name="market" value="Amazon" readOnly mb="5" />

              <FormLabel htmlFor="pic1" color={textColor}>
                Order Picture
              </FormLabel>
              <Input
                color={textColor}
                mb="20px"
                type="file"
                name="pic1"
                sx={{
                  height: 'auto',
                  padding: '.4375rem .75rem',
                  fontSize: '.8125rem',
                  lineHeight: 1.5,
                  border: '1px solid #e1e5eb',
                  fontWeight: 300,
                  willChange: 'border-color, box-shadow',
                  borderRadius: '.25rem',
                  boxShadow: 'none',
                  transition:
                    'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                }}
                onChange={(e) => handleFileUpload(e, 'OrderPic')}
                required
              />
              <FormLabel htmlFor="pic2" color={textColor}>
                Refund Picture
              </FormLabel>
              <Input
                color={textColor}
                mb="20px"
                type="file"
                name="pic2"
                sx={{
                  height: 'auto',
                  padding: '.4375rem .75rem',
                  fontSize: '.8125rem',
                  lineHeight: 1.5,
                  border: '1px solid #e1e5eb',
                  fontWeight: 300,
                  willChange: 'border-color, box-shadow',
                  borderRadius: '.25rem',
                  boxShadow: 'none',
                  transition:
                    'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                }}
                onChange={(e) => handleFileUpload(e, 'Refundpic')}
              />

              <Input
                color={textColor}
                type="text"
                name="reviewPicURL"
                value={amzReviewLink}
                onChange={(e) => setAmzReviewLink(e.target.value)}
                placeholder="AMZ Review Link"
                mb="5"
              />
            </Box>
          </Flex>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            color="black"
            background="rgb(231, 231, 231)"
            border="none"
            borderRadius="8px"
            boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
            w="100%"
            h="50px"
            mb="24px"
            _hover={{ bg: '#003042', color: 'white' }}
            type="submit"
          >
            Add Order
          </Button>
        </FormControl>
      </form>
    </Card>
  );
}
