import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Text,
  Skeleton,
  Button,
  Flex,
  Select,
  keyframes,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ComplexTable from "views/admin/orders/components/ViewOrders";
import apiClient from "../../../authclient/authclient";
import { useNavigate, useLocation } from 'react-router-dom';

const shadowAnimation = keyframes`
  0% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
  50% { box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
  100% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
`;

export default function UserReports() {
  const navigate = useNavigate();
  const brandColor = useColorModeValue("white","#111c44" );
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [selectedProductType, setSelectedProductType] = useState("all");
  const markets = [
    { value: 'all', label: 'all', fullName: 'All Market' },

    { value: 'US', label: 'US', fullName: 'United States' },
    { value: 'UK', label: 'UK', fullName: 'United Kingdom' },
    { value: 'DE', label: 'DE', fullName: 'Germany' },
    { value: 'IT', label: 'IT', fullName: 'Italy' },
    { value: 'CA', label: 'CA', fullName: 'Canada' },
    { value: 'ES', label: 'ES', fullName: 'Spain' },
    { value: 'AUS', label: 'AUS', fullName: 'Australia' },
    { value: 'JAPAN', label: 'JAPAN', fullName: 'Japan' },
    { value: 'KSA', label: 'KSA', fullName: 'Saudi Arabia' },
    {
      value: 'UAE',
      label: 'UAE',
      fullName: 'United Arab Emirates',
    },
    { value: 'General', label: 'General', fullName: 'General' },
    { value: 'FR', label: 'FR', fullName: 'France' },
    { value: 'Mexico', label: 'Mexico', fullName: 'Mexico' },
    { value: 'Russia', label: 'Russia', fullName: 'Russia' },
    { value: 'Sweden', label: 'Sweden', fullName: 'Sweden' },
    {
      value: 'Netherlands',
      label: 'Netherlands',
      fullName: 'Netherlands',
    },
    {
      value: 'US-High Commission',
      label: 'US-High Commission',
      fullName: 'United States High Commission',
    },
    {
      value: 'UK-High Commission',
      label: 'UK-High Commission',
      fullName: 'United Kingdom High Commission',
    },
    {
      value: 'Walmart-US',
      label: 'Walmart-US',
      fullName: 'Walmart United States',
    },
    { value: 'Turkey', label: 'Turkey', fullName: 'Turkey' },
    {
      value: 'Kindle Books',
      label: 'Kindle Books',
      fullName: 'Kindle Books',
    },
    { value: 'ETSY', label: 'ETSY', fullName: 'ETSY' },
    {
      value: 'Singapore',
      label: 'Singapore',
      fullName: 'Singapore',
    },
    { value: 'India', label: 'India', fullName: 'India' },
    {
      value: 'Kindle US',
      label: 'Kindle US',
      fullName: 'Kindle United States',
    },
    {
      value: 'eBay US',
      label: 'eBay US',
      fullName: 'eBay United States',
    },
    {
      value: 'Walmart-UK',
      label: 'Walmart-UK',
      fullName: 'Walmart United Kingdom',
    },
    { value: 'Poland', label: 'Poland', fullName: 'Poland' },
    {
      value: 'TikTok US',
      label: 'TikTok US',
      fullName: 'TikTok United States',
    },
    {
      value: 'Walmart-CA',
      label: 'Walmart-CA',
      fullName: 'Walmart Canada',
    },
    {
      value: 'Ali Express',
      label: 'Ali Express',
      fullName: 'Ali Express',
    },
    {
      value: 'Temu-US',
      label: 'Temu-US',
      fullName: 'Temu United States',
    },
    {
      value: 'Otto-DE',
      label: 'Otto-DE',
      fullName: 'Otto Germany',
    },
    {
      value: 'Temu-UK',
      label: 'Temu-UK',
      fullName: 'Temu United Kingdom',
    },
    {
      value: 'SHEIN-US',
      label: 'SHEIN-US',
      fullName: 'SHEIN United States',
    },
    {
      value: 'Temu-DE',
      label: 'Temu-DE',
      fullName: 'Temu Germany',
    },
  ];
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
 const response = await apiClient.get(`orders`, {
        params: {
          market: selectedCountry,
          productType: selectedProductType,
          status: "Delivered",
          page: pageIndex + 1,
          size: pageSize,
        },
      });

      // console.log(response);
      if (response.data) {
     
     setTableData(response.data.orders);
     setTotalPages(response.data.totalPages);
      }
    } catch (err) {
      if (err.response && err.response.status === 403) {
        navigate('/login'); // Redirect to login if 403 Forbidden error occurs
      } 
      //console.error("Error fetching data:", err);
     
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, selectedCountry, selectedProductType]);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={1} spacing={4}>
          {[...Array(7)].map((_, index) => (
            <Box key={index} height="50px" borderRadius="10px" animation={`${shadowAnimation} 1.5s infinite`} overflow="hidden">
              <Skeleton height="100%" borderRadius="10px" fadeDuration={1} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} textAlign="center">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Flex
          px="25px"
         bg= {brandColor}// White in light mode, dark gray in dark mode
          justifyContent="space-between"
          align="center"
          gap={2}
          borderTopLeftRadius="15px"
          borderTopRightRadius="15px"
        >
          <Text fontSize="22px" fontWeight="200" mt="15px">
            Overview Orders
          </Text>
          <Flex  mt="15px" mb="0px" justifyContent="space-between" align="center" gap={1}>
            <Flex align="center" gap={1}>
      

<Select
  value={selectedCountry}
  onChange={(e) => {
    setSelectedCountry(e.target.value);
    setPageIndex(0);
  }}
  width="100px"
  lineHeight="100%"
  borderRadius="10px"
  ml="10px"
>
  <option value="" disabled>Market Select</option>
  {markets.map((market, index) => (
    <option key={index} value={market.value}>
      {market.fullName}
    </option>
  ))}
</Select>

          
              <Select
                value={selectedProductType}
                onChange={(e) => {
                  setSelectedProductType(e.target.value);
                  setPageIndex(0);
                }}
                width="125px"
                lineHeight="100%"
                borderRadius="10px"
                ml="10px"
              >
            <option value="" disabled>Type</option>
            <option value="all">All Type</option>
  <option value="Text Review">Text Review</option>
  <option value="Top Reviewer">Top Reviewer</option>
  <option value="No review">No review</option>
  <option value="Feedback">Feedback</option>
  <option value="Rating">Rating</option>
  <option value="RAS">RAS</option>
  <option value="RAO">RAO</option>
  <option value="Seller Testing">Seller Testing</option>
  <option value="Pic Review">Pic Review</option>
  <option value="Vid Review">Vid Review</option>  </Select>

              {/* <Select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          width="80px"
                lineHeight="100%"
                borderRadius="10px"
                ml="10px"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </Select> */}
            </Flex>
          </Flex>
        </Flex>

        <ComplexTable tableData={tableData} phoneNumber="1234567890" pageSize={pageSize} />

        {totalPages > 1  && (
  <Flex justifyContent="space-between" align="center" p="15px"
  >
    <Button
      onClick={() => setPageIndex((old) => Math.max(0, old - 1))}
      disabled={pageIndex === 0}
    >
      Previous
    </Button>
    <Text>
      Page {pageIndex + 1} of {totalPages}
    </Text>
    <Button
      onClick={() => setPageIndex((old) => Math.min(totalPages - 1, old + 1))}
      disabled={pageIndex >= totalPages - 1}
    >
      Next
    </Button>
  </Flex>
)}
      </SimpleGrid>
    </Box>
  );
}
