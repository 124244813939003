// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/orders/components/OrderBox";
import General from "views/admin/orders/components/OrderInformation";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/uploadpic.jpg";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Ensure you have this for navigation
import apiClient from "../../../authclient/authclient"; // Import your API client

export default function Overview() {
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchOrderData();
  }, [navigate]);
  const fetchOrderData = async () => {
    try { 
      // Assuming `orderId` is stored in localStorage or is part of the URL params.
      const orderId = localStorage.getItem("orderId"); // Replace with actual method of getting the order ID
      if (!orderId) {
        navigate('/admin/vieworders'); // If no order ID, navigate to order list page
        return;
      } 

      // API call to fetch order data using the order ID
      const response = await apiClient.get(`vieworder/${orderId}`);
      setOrderData(response.data);
       // Assuming response data contains the order details
    } catch (err) {
      setError('Failed to fetch order data.');
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };
  
  if (loading) {
    return <Box>Loading...</Box>; // Show loading state
  }

  return (
    <Box 
      pt={{ base: "130px", md: "80px", xl: "80px" }} // Padding top for different breakpoints
      mb={{ base: "20px", md: "30px", xl: "40px" }} // Bottom margin for different breakpoints
      width="100%" // Full width
    >
      {/* Main Fields */}
      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(3, 1fr)' }}
        gap={{ base: '15px', xl: '5px' }} // Adjust gap as needed
        width="100%"
      >
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 2 / 2" }} // First in small, first in large
          banner={banner}
          avatar={orderData.image1Url || avatar}
          OrderId={orderData._id }
          name='Order picture'
        />

        <Banner
          gridArea={{ base: "1 / 2 / 2 / 3", lg: "1 / 2 / 2 / 3" }} // Second in small, second in large
          banner={banner}
          avatar={orderData.image2Url || avatar}
          OrderId={orderData._id }
          name='Review picture'
        />
 
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 1 / 2" }} // First in small, first in large
          banner={banner}
          avatar={orderData.image3Url || avatar}
          OrderId={orderData._id }
          name='Refund picture'
        />
      </Grid>

      {/* Pass the fetched order data to General component */}
      <General
        gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }} // Second in small, second in large
        minH="365px"
        width={{ base: "100%", lg: "100%" }}
        mt={{ base: "20px", md: "30px", lg: "40px" }} // Adjust top margin based on breakpoints
        orderData={orderData} // Pass the fetched orderData here
        toggleEnableFunction={fetchOrderData}      />
    </Box>
  );
}
