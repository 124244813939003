// Chakra imports
import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  HStack,
  Select,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { MdEdit, MdClose } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card.js';
import React, { useEffect, useState } from 'react';
import Information from 'views/admin/profile/components/Information';
import apiClient from '../../../../authclient/authclient';
import Swal from 'sweetalert2';

// Assets
export default function GeneralInformation({
  productData,
  toggleEnableFunction,
  ...rest
}) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );
  function copy_vcode(value) {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(value)
        .then(function () {
          alert('Copied the text: ' + value);
        })
        .catch(function (err) {
          //console.error('Failed to copy text: ', err);
        });
    } else {
      const el = document.createElement('textarea');
      el.value = value;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Copied the text: ' + value);
    }
  }

  const textStyle = {
    overflowY: 'auto', // Enable vertical scrolling when content overflows
    maxHeight: '200px', // Limit the height of the container
    whiteSpace: 'normal', // Allow text to wrap normally
    scrollbarWidth: 'none', // Firefox specific: Hide scrollbar (works in Firefox)
  };
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);
  const [isEditing, setIsEditing] = useState(false);

  // Initialize form state with productData or default values
  const [orderData, setOrderData] = useState({
    Keyword: productData?.Keyword || '',
    SoldBy: productData?.SoldBy || '',
    BrandName: productData?.BrandName || '',
    ProductID: productData?.ProductID || '',
    Market: productData?.Market || '',
    Commission: productData?.Commission || '',
    ChineseSeller: productData?.chineseSeller || '',
    ProductType: productData?.ProductType || '',
    SellerName: productData?.SellerName || '',
    OverallSaleLimit: productData?.OverallSaleLimit || '',
    DailySaleLimit: productData?.DailySaleLimit || '',
    Instructions: productData?.Instructions || '',
    RefundCondition: productData?.RefundCondition || '',
    CommissionCondition: productData?.CommissionCondition || '',
  });

  // State for individual form fields
  const [keyword, setKeyword] = useState(orderData.Keyword);
  const [sellerId, setSellerId] = useState(orderData.SoldBy);
  const [brandName, setBrandName] = useState(orderData.BrandName);
  const [amzSeller, setAmzSeller] = useState(orderData.SoldBy);
  const [market, setMarket] = useState(orderData.Market);
  const [chineseSeller, setChineseSeller] = useState(orderData.ChineseSeller);
  const [category, setCategory] = useState('');
  const [productType, setProductType] = useState(orderData.ProductType);
  const [commission, setCommission] = useState(orderData.Commission);
  const [mercantileCommission, setMercantileCommission] = useState('175');
  const [instruction, setInstruction] = useState(orderData.Instructions);
  const [commissionConditions, setCommissionConditions] = useState(
    orderData.CommissionCondition,
  );
  const [refundConditions, setRefundConditions] = useState(
    orderData.RefundCondition,
  );
  const [saleLimit, setSaleLimit] = useState(orderData.DailySaleLimit);
  const [overallSaleLimit, setOverallSaleLimit] = useState(
    orderData.OverallSaleLimit,
  );
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Toggle edit mode
  const handleToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdateProduct = async () => {
    setError('');
    setSuccess('');

    // Prepare the updated user data, only including fields that have changed
    const updatedProduct = {
      keyword,
      amzSeller,
      brandName,
      market,
      chineseSeller,
      category,
      productType,
      commission, // renamed to `commission` (AdverzPro commission)
      mercantileCommission, // Mercantile Commission
      instruction,
      commissionConditions,
      refundConditions, // Refund conditions field
      saleLimit,
      overallSaleLimit,
    };
    // console.log(updatedProduct);
    // Make the PUT request to update the user data
    try {
      const response = await apiClient.put(
        `updateProduct/${productData.ProductID}`,
        updatedProduct,
      );
      setSuccess('User updated successfully!');
      Swal.fire({
        title: 'Success!',
        text: 'Product updated successfully!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
      setIsEditing(false);
    } catch (error) {
      if (error.response) {
        setError(
          error.response.data.message ||
            'Failed to update user. Please try again.',
        );
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      Swal.fire({
        title: 'Error!',
        text: errorMsg || 'An unexpected error occurred. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    }

    toggleEnableFunction();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateProduct();
  };

  return (
    <Card
      mb={{ base: '0px', '2xl': '20px' }}
      maxWidth="100%"
      padding="20px"
      {...rest}
    >
      <HStack spacing="20px" mt="10px">
        <Text fontWeight="bold" fontSize="2xl">
          {isEditing ? 'Product Update' : 'Product Information'}
        </Text>
        {userRole !== 'Pm' && (
          <Button
            onClick={handleToggle}
            colorScheme="none"
            backgroundColor="transparent"
            color={isEditing ? 'red' : 'white'}
            leftIcon={isEditing ? <MdClose color="red" /> : <MdEdit />}
            _hover={{
              backgroundColor: isEditing
                ? 'rgba(255, 0, 0, 0.1)'
                : 'rgba(0, 0, 255, 0.1)',
            }}
          >
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        )}
      </HStack>

      {isEditing ? (
        // Editable form fields
        <>
          <form onSubmit={handleSubmit}>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="7px">
              <FormControl>
                <FormLabel>Keyword</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Seller ID</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={sellerId}
                  onChange={(e) => setSellerId(e.target.value)}
                  isDisabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Brand Name</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
              <FormControl>
                <FormLabel>AMZ Seller (Sold By)</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={amzSeller}
                  onChange={(e) => setAmzSeller(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Market</FormLabel>
                <Select
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={market}
                  onChange={(e) => setMarket(e.target.value)}
                >
                  {[
                    'US',
                    'UK',
                    'DE',
                    'IT',
                    'CA',
                    'ES',
                    'AUS',
                    'JAPAN',
                    'KSA',
                    'UAE',
                    'FR',
                    'MX',
                    'RU',
                    'SE',
                    'NL',
                  ].map((market, index) => (
                    <option key={index} value={market}>
                      {market}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </SimpleGrid>

            <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
              <FormControl>
                <FormLabel>Chinese Seller</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={chineseSeller}
                  onChange={(e) => setChineseSeller(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Select
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {[
                    'Electronics',
                    'Health & Beauty',
                    'Baby products',
                    'Fashion',
                    'Gaming devices',
                    'Home & Kitchen',
                  ].map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Product Type</FormLabel>
                <Select
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  {[
                    'Text Review',
                    'Top Reviewer',
                    'No review',
                    'Feedback',
                    'Rating',
                    'RAS',
                    'RAO',
                    'Seller Testing',
                    'Pic Review',
                    'Vid Review',
                  ].map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </SimpleGrid>

            {/* Other form fields for commission, instructions, limits */}
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
              <FormControl>
                <FormLabel>Commission Amount</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  type="number"
                  value={commission}
                  onChange={(e) => setCommission(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Mercantile Commission</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  type="number"
                  value={mercantileCommission}
                  onChange={(e) => setMercantileCommission(e.target.value)}
                  min="175"
                />
              </FormControl>
            </SimpleGrid>

            <FormControl mt="20px">
              <FormLabel>Instruction</FormLabel>
              <Textarea
                height="100px"
                boxShadow={cardShadow}
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
              />
            </FormControl>
            <FormControl mt="20px">
              <FormLabel>Commission Conditions</FormLabel>
              <Textarea
                height="100px"
                boxShadow={cardShadow}
                value={commissionConditions}
                onChange={(e) => setCommissionConditions(e.target.value)}
              />
            </FormControl>
            <FormControl mt="20px">
              <FormLabel>Refund Conditions</FormLabel>
              <Textarea
                height="100px"
                boxShadow={cardShadow}
                value={refundConditions}
                onChange={(e) => setRefundConditions(e.target.value)}
              />
            </FormControl>

            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
              <FormControl>
                <FormLabel>Daily Sale Limit</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  type="number"
                  value={saleLimit}
                  onChange={(e) => setSaleLimit(e.target.value)}
                  min="0"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Overall Sale Limit</FormLabel>
                <Input
                  color={textColorPrimary}
                  boxShadow={cardShadow}
                  type="number"
                  value={overallSaleLimit}
                  onChange={(e) => setOverallSaleLimit(e.target.value)}
                  min="0"
                />
              </FormControl>
            </SimpleGrid>
            <Button
              type="submit"
              fontSize="sm"
              fontWeight="700"
              w="100%"
              h="50px"
              mt="20px"
              mb="20px"
              _hover={{ bg: '#003042', color: 'white' }}
            >
              Update Product
            </Button>
          </form>
        </>
      ) : (
        // Display non-editable information
        <>
          {/* Information components display */}
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="7px">
            <Information
              boxShadow={cardShadow}
              title="Keyword"
              value={orderData.Keyword}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Sold By"
              value={orderData.SoldBy}
              style={textStyle}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title="Brand Name"
              value={orderData.BrandName}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Product ID"
              value={orderData.ProductID}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Market"
              value={orderData.Market}
              style={textStyle}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title="Commission"
              value={orderData.Commission}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Chinese Seller"
              value={orderData.ChineseSeller}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Product Type"
              value={orderData.ProductType}
              style={textStyle}
            />
            <Information
              boxShadow={cardShadow}
              title="Seller Name"
              value={orderData.SellerName}
              style={textStyle}
            />
          </SimpleGrid>
          <Information
            boxShadow={cardShadow}
            title="Instructions"
            value={orderData.Instructions}
            style={textStyle}
            mt="20px"
          />
          <Information
            boxShadow={cardShadow}
            title="Refund Condition"
            value={orderData.RefundCondition}
            style={textStyle}
            mt="20px"
          />
          <Information
            boxShadow={cardShadow}
            title="Commission Condition"
            value={orderData.CommissionCondition}
            style={textStyle}
            mt="20px"
          />

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            gap="20px"
            mt="20px"
            mb="20px"
          >
            <Information
              boxShadow={cardShadow}
              title="Daily Sale Limit"
              value={orderData.DailySaleLimit}
              style={textStyle}
            />

            <Information
              boxShadow={cardShadow}
              title="Overall Sale Limit"
              value={orderData.OverallSaleLimit}
              style={textStyle}
            />
          </SimpleGrid>
        </>
      )}
    </Card>
  );
}
