import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Select,
  useColorModeValue,
  Tag,
} from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
import Card from 'components/card/Card'; // Adjust the import path as necessary

// Shorten labels for display purposes
const shortenLabel = (label) => {
  return label.length > 10 ? label.slice(0, 10) + '...' : label;
};

// Function to get color based on the segment name
const getSegmentColor = (status) => {
  switch (status) {
    case 'Ordered':
      return 'blue.500';
    case 'Reviewed':
      return 'purple.500';
    case 'Delivered':
      return 'green.500';
    case 'Refunded':
      return 'red.500';
    case 'OnHold':
      return 'yellow.500';
    case 'Deleted':
      return 'gray.500';
    case 'Completed':
      return 'teal.500';
    case 'Cancelled':
      return 'red.600';
    default:
      return 'gray.300'; // Default color
  }
};

export default function PieCard({ pieChartData, ...rest }) {
  const [selectedSegment, setSelectedSegment] = useState(null);

  const handleSegmentClick = (event, chartContext, config) => {
    const segmentIndex = config.dataPointIndex;
    setSelectedSegment(pieChartData[segmentIndex]);
  };

  const series = pieChartData.map((item) => item.value);
  const labels = pieChartData.map((item) => shortenLabel(item.name));

  const pieChartOptions = {
    labels,
    chart: {
      type: 'pie',
      events: { dataPointSelection: handleSegmentClick },
    },
    legend: {
      show: true,
      position: 'right', // Display the legend to the right
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => `${opts.w.globals.labels[opts.seriesIndex]}`, // Only show name
    },
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardColor = useColorModeValue('white', 'navy.700');
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );

  // Label color for dark and light mode
  const labelTextColor = useColorModeValue('gray.800', 'white'); // Light mode: dark text, Dark mode: white text

  return (
    <Card
      p="20px"
      align="center"
      direction="column"
      h="100%"
      w="100%"
      {...rest}
    >
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Your Pie Chart
        </Text>
        <Text
          fontSize="sm"
          variant="subtle"
          defaultValue="monthly"
          width="unset"
          fontWeight="700"
        >
          Completed Order Status
        </Text>
      </Flex>

      <ReactApexChart
        options={pieChartOptions}
        series={series}
        type="pie"
        width="100%"
        height="250px"
      />
    </Card>
  );
}
