import {
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { MdEdit, MdClose } from 'react-icons/md';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Card from 'components/card/Card.js';
import Information from 'views/admin/profile/components/Information';
import apiClient from '../../../../authclient/authclient';
import Swal from 'sweetalert2';

export default function GeneralInformation({
  userData,
  toggleEnableFunction,
  ...rest
}) {
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(userData.name || '');
  const [gender, setGender] = useState(userData.gender || '');
  const [role, setRole] = useState(userData.userRole || '');
  const [phoneNumber, setPhoneNumber] = useState(userData.phone || '');
  const [address, setAddress] = useState(userData.address || '');
  const [status, setStatus] = useState(userData.status || '');
  const [bankName, setBankName] = useState(userData.bankName || '');
  const [accountNumber, setAccountNumber] = useState(
    userData.accountNumber || '',
  );
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleToggle = () => setIsEditing(!isEditing);

  const handleUpdateUser = async () => {
    setError('');
    setSuccess('');

    // Prepare the updated user data, only including fields that have changed
    const updatedUser = {
      name,
      gender,
      role,
      phone: phoneNumber,
      address,
      status,
      bankName,
      accountNumber,
    };
    // console.log(updatedUser);
    // Make the PUT request to update the user data
    try {
      const response = await apiClient.put(
        `updateUser/${userData.userId}`,
        updatedUser,
      );
      setSuccess('User updated successfully!');
      setIsEditing(false);
      Swal.fire({
        title: 'Success!',
        text: 'User updated successfully!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      if (error.response) {
        setError(
          error.response.data.message ||
            'Failed to update user. Please try again.',
        );
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      Swal.fire({
        title: 'Error!',
        text: errorMsg || 'An unexpected error occurred. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    }
    toggleEnableFunction();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateUser();
  };

  return (
    <Card
      mb={{ base: '0px', '2xl': '20px' }}
      maxWidth="100%"
      padding="20px"
      {...rest}
    >
      <HStack spacing="20px" mt="10px">
        <Text fontWeight="bold" fontSize="2xl">
          User Information
        </Text>
        <Button
          onClick={handleToggle}
          colorScheme="none"
          border="transparent"
          backgroundColor="transparent"
          color={isEditing ? 'red' : 'white'}
          leftIcon={isEditing ? <MdClose color="red" /> : <MdEdit />}
          _hover={{
            backgroundColor: isEditing
              ? 'rgba(255, 0, 0, 0.1)'
              : 'rgba(0, 0, 255, 0.1)',
          }}
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </HStack>

      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="5px">
            {/* Name Field */}
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                color="gray.400"
                value={name}
                placeholder="Name"
                mb="2px"
                fontWeight="500"
                size="lg"
                onChange={(e) => {
                  setName(e.target.value);
                  setError(''); // Reset error message
                  setSuccess(''); // Reset success message
                }}
                isRequired
              />
            </FormControl>

            {/* Username Field */}
            <FormControl>
              <FormLabel>Username</FormLabel>
              <Input
                color="gray.400"
                value={userData.userName || ''}
                mb="2px"
                fontWeight="500"
                size="lg"
                disabled
              />
            </FormControl>

            {/* Gender Field */}
            <FormControl>
              <FormLabel>Gender</FormLabel>
              <Select
                value={userData.gender}
                placeholder="Select Gender"
                mb="2px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Select>
            </FormControl>
          </SimpleGrid>

          {/* Email and Role Fields */}
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                color="gray.400"
                value={userData.email || ''}
                mb="2px"
                fontWeight="500"
                size="lg"
                disabled
              />
            </FormControl>
            <FormControl>
              <FormLabel>Select Role</FormLabel>
              <Select
                value={userData.userRole}
                placeholder="Select Role"
                mb="2px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="SuperAdmin">SuperAdmin</option>
                <option value="Admin">Admin</option>
                <option value="Seller">Seller</option>
                <option value="Pm">Pm</option>
              </Select>
            </FormControl>
          </SimpleGrid>

          {/* Address, Phone Number, and Status Fields */}
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
            <FormControl>
              <FormLabel>Address</FormLabel>
              <Input
                color="gray.400"
                value={address}
                mb="2px"
                fontWeight="500"
                size="lg"
                onChange={(e) => setAddress(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Phone Number</FormLabel>
              <Input
                color="gray.400"
                type="tel"
                placeholder="Enter your phone number"
                value={phoneNumber}
                mb="2px"
                required
                size="lg"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                placeholder="Select Status"
                mb="24px"
                size="lg"
              >
                <option value="active">Active</option>
                <option value="warned">Warning</option>
                <option value="blocked">Blocked</option>
              </Select>
            </FormControl>
          </SimpleGrid>

          {/* Bank Info Fields */}
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
            <FormControl>
              <FormLabel>Bank Name</FormLabel>
              <Input
                color="gray.400"
                value={bankName}
                placeholder="Bank Name"
                mb="2px"
                required
                fontWeight="500"
                size="lg"
                onChange={(e) => setBankName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Account Number</FormLabel>
              <Input
                color="gray.400"
                type="text"
                placeholder="Enter your account number"
                value={accountNumber}
                mb="20px"
                required
                size="lg"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          {/* Submit Button */}
          <Button
            type="submit"
            fontSize="sm"
            fontWeight="700"
            w="100%"
            h="50px"
            mt="10px"
            mb="20px"
            _hover={{ bg: '#003042', color: 'white' }}
          >
            Update User
          </Button>
        </form>
      ) : (
        <>
          {/* Display information when not in edit mode */}
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="8px">
            <Information
              boxShadow={cardShadow}
              title="Name"
              value={userData.name || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Username"
              value={userData.userName || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Gender"
              value={userData.gender || ''}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title="Phone Number"
              value={userData.phone || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Role"
              value={userData.userRole || ''}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title="Email"
              value={userData.email || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Address"
              value={userData.address || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Status"
              value={userData.status || ''}
            />
          </SimpleGrid>

          {/* Display bank info when not in edit mode */}
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px" mb="6">
            <Information
              boxShadow={cardShadow}
              title="Bank Name"
              value={userData.bankName || ''}
            />
            <Information
              boxShadow={cardShadow}
              title="Account Number"
              value={userData.accountNumber || ''}
            />
          </SimpleGrid>
        </>
      )}
    </Card>
  );
}
