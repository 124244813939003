import React, { useState } from 'react';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { Box, Button, Text, Flex } from '@chakra-ui/react';
import apiClient from '../../../authclient/authclient';

const MyComponent = () => {
  const [lock, setLock] = useState(null);
  const toggleLock = async () => {
    try {
      const response = await apiClient.put('lockAccount');
      // console.log(response);
      setLock((prev) => (prev === null ? 1 : null));
    } catch (error) {
      if (error.response) {
        //console.error('Response error:', error.response.data);
      } else if (error.request) {
        //console.error('Request error:', error.request);
      } else {
        //console.error('Error:', error.message);
      }
    }
  };

  // Determine button styles and text color based on the lock state
  const buttonColor = lock !== null ? 'red.500' : 'green.500';
  const buttonBorderColor = lock !== null ? 'red.300' : 'green.300';
  const buttonText = lock !== null ? 'Unlock' : 'Lock';
  const textColor = lock !== null ? 'red.600' : 'green.600'; // Change text color based on lock state

  // Add shadow based on lock state
  const buttonShadow = lock !== null ? 'lg' : 'md'; // Change shadow size based on lock state

  return (
    <Box p={4} textAlign="center">
      <Flex justifyContent="center" alignItems="center">
        <Text fontSize="lg" color={textColor} mr={2}>
          {lock !== null ? 'Locked' : 'Unlocked'}
        </Text>

        <Text fontSize="sm">Multi-Account Login</Text>
      </Flex>

      <Flex justifyContent="center" alignItems="center" mt={2}>
        {lock !== null ? (
          <LockIcon boxSize={6} color="blue.500" />
        ) : (
          <UnlockIcon boxSize={6} color="green.500" />
        )}
        <Button
          ml={4}
          onClick={toggleLock}
          bg={buttonColor}
          color="white"
          border={`2px solid ${buttonBorderColor}`}
          _hover={{ bg: lock !== null ? 'red.400' : 'green.400' }}
          boxShadow={buttonShadow} // Set shadow based on lock state
        >
          {buttonText}
        </Button>
      </Flex>
    </Box>
  );
};

export default MyComponent;
