import { Box, Grid } from "@chakra-ui/react";
import Projects from "views/admin/users/components/AddUser";
import React from "react";

export default function Overview() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>  
      <Grid
  mb='20px'
  templateColumns={{
    base: "1fr",
    lg: "repeat(1, 1fr)", 
    "2xl": "1.34fr 1.62fr 1fr", 
  }}
  templateRows={{
    base: "1fr", 
    lg: "1fr", 
    "2xl": "1fr", 
  }}
  
  gap={{ base: "20px", xl: "20px" }}
>
        <Projects/>
      </Grid>
    </Box>
  );
}
