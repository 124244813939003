import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  MdPerson,
  MdHome,
  MdLogout,
  MdVisibility,
  MdSettings,
  MdDelete,
  MdReceipt,
  MdLock,
  MdWarning,
  MdEvent,
  MdShoppingCart,
  MdAttachMoney,
  MdRequestPage,
  MdAccountCircle,
  MdAccountBalance,
  MdBookmark,
  MdLockReset,
  MdNote,
  MdAdd,
  MdBlock,
  MdEmojiPeople,
  MdError,
  MdCheckCircle,
  MdCheck,
  MdMoneyOff,
  MdCancel,
  MdHourglassEmpty,
  MdList,
  MdHistory,
  MdStar,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/dashboard';
import Profile from 'views/admin/profile';
import Users from 'views/admin/users/viewusers';
import BlacklistedUsers from 'views/admin/users/blacklisted';
import WarningsUsers from 'views/admin/users/warnings';
import ActiveUsers from 'views/admin/users/actives';
import AddUser from 'views/admin/users/adduser';
import AddProduct from 'views/admin/products/addproduct';
import Products from 'views/admin/products/viewproducts';
import EnableProduct from 'views/admin/products/enableproduct';
import DisableProduct from 'views/admin/products/disableproducts';
import Orders from 'views/admin/orders/vieworders';
import Reservations from 'views/admin/reservations/viewreservation';
import AddReserve from 'views/admin/reservations/addreserve';
import RequestReserve from 'views/admin/reservations/requestreserve';
import ChangePassword from 'views/admin/password/changepassword';
import AddAccount from 'views/admin/account/addaccount';
import History from 'views/admin/history/viewhistory';
import ChangeUserPassword from 'views/admin/password/changeuserpassword';
import ViewCommission from 'views/admin/Commission/viewcommission';
import Accounts from 'views/admin/account/viewaccounts';

import Ordered from 'views/admin/orders/ordered';
import Reviewed from 'views/admin/orders/reviewed';
import Delivered from 'views/admin/orders/delivered';
import Refunded from 'views/admin/orders/refunded';
import Cancelled from 'views/admin/orders/cancelled';
import OnHold from 'views/admin/orders/onhold';
import Deleted from 'views/admin/orders/deleted';
import Completed from 'views/admin/orders/completed';
// Auth Imports
import UsersProfileInfo from 'views/admin/users/usersprofileinfo';
import SignInCentered from 'views/auth/login';
import Logout from 'views/auth/logout';
import OrderInfo from 'views/admin/orders/orderinfo';
import AddOrder from 'views/admin/orders/addorder';
import UpdateAccount from 'views/admin/account/updateaccount';
import ProductInfo from 'views/admin/products/productinfo';
const routes = [
  // Dashboard
  {
    name: 'Dashboard',
    layout: '/admin',
    path: '/dashboard',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Overview Details',
    layout: '/admin',
    path: '/usersprofileinfo',
    component: <UsersProfileInfo />,
  },

  // Users

  {
    name: 'Create New User',
    layout: '/admin',
    path: '/add-user',
    icon: <Icon as={MdAdd} width="20px" height="20px" color="inherit" />,
    component: <AddUser />,
  },
  {
    name: 'Overview Users',
    layout: '/admin',
    path: '/user-list',
    icon: (
      <Icon as={MdEmojiPeople} width="20px" height="20px" color="inherit" />
    ),
    component: <Users />,
  },
  {
    name: 'Active Users',
    layout: '/admin',
    path: '/active-users',
    icon: <Icon as={MdVisibility} width="20px" height="20px" color="inherit" />,
    component: <ActiveUsers />,
  },
  {
    name: 'Blocklist Users',
    layout: '/admin',
    path: '/blocked-users',
    icon: <Icon as={MdBlock} width="20px" height="20px" color="inherit" />,
    component: <BlacklistedUsers />,
  },
  {
    name: 'Warning Users',
    layout: '/admin',
    path: '/warning-users',
    icon: <Icon as={MdError} width="20px" height="20px" color="inherit" />,
    component: <WarningsUsers />,
  },

  // Products
  {
    name: 'Add Product',
    layout: '/admin',
    path: '/addproduct',
    icon: <Icon as={MdAdd} width="20px" height="20px" color="inherit" />,
    component: <AddProduct />,
  },
  {
    name: 'Overview Products',
    layout: '/admin',
    path: '/viewproducts',
    icon: <Icon as={MdVisibility} width="20px" height="20px" color="inherit" />,
    component: <Products />,
  },
  {
    name: 'Enable Product',
    layout: '/admin',
    path: '/enableproduct',
    icon: <Icon as={MdVisibility} width="20px" height="20px" color="inherit" />,
    component: <EnableProduct />,
  },
  {
    name: 'Disable Product',
    layout: '/admin',
    path: '/disableproduct',
    icon: <Icon as={MdBlock} width="20px" height="20px" color="inherit" />,
    component: <DisableProduct />,
  },

  {
    name: 'Info Products',
    layout: '/admin',
    path: '/Productinfo',
    component: <ProductInfo />,
  },
  // Orders
  {
    name: 'Overview Orders',
    layout: '/admin',
    path: '/vieworders',
    icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,
    component: <Orders />,
  },
  {
    name: 'Ordered',
    layout: '/admin',
    path: '/ordered',
    icon: (
      <Icon as={MdShoppingCart} width="20px" height="20px" color="inherit" />
    ),
    component: <Ordered />,
  },
  {
    name: 'Reviewed',
    layout: '/admin',
    path: '/reviewed',
    icon: <Icon as={MdStar} width="20px" height="20px" color="inherit" />,
    component: <Reviewed />,
  },
  {
    name: 'Delivered',
    layout: '/admin',
    path: '/delivered',
    icon: <Icon as={MdCheck} width="20px" height="20px" color="inherit" />,
    component: <Delivered />,
  },
  {
    name: 'Refunded',
    layout: '/admin',
    path: '/refunded',
    icon: <Icon as={MdMoneyOff} width="20px" height="20px" color="inherit" />,
    component: <Refunded />,
  },
  {
    name: 'Cancelled',
    layout: '/admin',
    path: '/cancelled',
    icon: <Icon as={MdCancel} width="20px" height="20px" color="inherit" />,
    component: <Cancelled />,
  },
  {
    name: 'On Hold',
    layout: '/admin',
    path: '/onhold',
    icon: (
      <Icon as={MdHourglassEmpty} width="20px" height="20px" color="inherit" />
    ),
    component: <OnHold />,
  },
  {
    name: 'Review Deleted',
    layout: '/admin',
    path: '/deleted',
    icon: <Icon as={MdDelete} width="20px" height="20px" color="inherit" />,
    component: <Deleted />,
  },
  {
    name: 'Completed',
    layout: '/admin',
    path: '/completed',
    icon: (
      <Icon as={MdCheckCircle} width="20px" height="20px" color="inherit" />
    ),
    component: <Completed />,
  },

  // Reservations
  {
    name: 'Add Reservation',
    layout: '/admin',
    path: '/addreserve',
    icon: <Icon as={MdAdd} width="20px" height="20px" color="inherit" />,
    component: <AddReserve />,
  },
  {
    name: 'View Reservations',
    layout: '/admin',
    path: '/viewreservation',
    icon: <Icon as={MdVisibility} width="20px" height="20px" color="inherit" />,
    component: <Reservations />,
  },

  // History
  {
    name: 'Overview History',
    layout: '/admin',
    path: '/history',
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: <History />,
  },
  {
    name: 'History',
    layout: '/admin',
    path: '/history',
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: <History />,
  },
  // Change Password
  {
    name: 'Change Password',
    layout: '/admin',
    path: '/changepassword',
    icon: <Icon as={MdLockReset} width="20px" height="20px" color="inherit" />,
    component: <ChangePassword />,
  },

  // Change User Password
  {
    name: 'Update Passwords',
    layout: '/admin',
    path: '/changeuserpassword',
    icon: <Icon as={MdLockReset} width="20px" height="20px" color="inherit" />,
    component: <ChangeUserPassword />,
  },

  // Commission
  {
    name: 'View Commission',
    layout: '/admin',
    path: '/viewcommission',
    icon: (
      <Icon as={MdAttachMoney} width="20px" height="20px" color="inherit" />
    ),
    component: <ViewCommission />,
  },

  // Profile
  {
    name: 'Overview Profile',
    layout: '/admin',
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },

  {
    name: 'Order Details',
    layout: '/admin',
    path: '/orderinfo',
    component: <OrderInfo />,
  },

  {
    name: 'Create Order',
    layout: '/admin',
    path: '/addOrder',
    component: <AddOrder />,
  },

  // Auth Routes
  {
    name: 'Logout',
    layout: '/auth',
    path: '/logout',
    icon: <Icon as={MdLogout} width="20px" height="20px" color="red.500" />,
    component: <Logout />,
  },

  {
    name: 'Logout',
    layout: '/auth',
    path: '/login',
    icon: <Icon as={MdLogout} width="20px" height="20px" color="red.500" />,
    component: <SignInCentered />,
  },
];

export default routes;
