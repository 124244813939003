// src/pages/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

// NotFound Component
const NotFound = () => {
  return (
    <div style={styles.notFound}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.message}>
        Oops! The page you are looking for does not exist.
      </p>
      <Link to="/" style={styles.backHome}>
        Go Back to Home
      </Link>
    </div>
  );
};

// Inline styles for the component
const styles = {
  notFound: {
    textAlign: 'center',
    padding: '50px',
  },
  heading: {
    fontSize: '100px',
    color: '#ff6347',
  },
  message: {
    fontSize: '20px',
    color: 'gray.500',
  },
  backHome: {
    display: 'inline-block',
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#ff6347',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
  },
};

export default NotFound;
