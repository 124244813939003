import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState, useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import routes from 'routes.js';
import Cookies from 'js-cookie';
import axios from 'axios';
import apiClient from '../../authclient/authclient';

export default function Dashboard(props) {
  const { ...rest } = props;
  const location = useLocation(); // Hook to track the current path
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { onOpen } = useDisclosure();
  const [activeRoute, setActiveRoute] = useState('My Home'); // State for active route
  const [isAuthorized, setIsAuthorized] = useState(null); // State for authorization status
  const [loading, setLoading] = useState(true); // State to track loading state
  const navigate = useNavigate();

  // Function to check active route based on the current location
  const getActiveRoute = (routes) => {
    let activeRoute = 'My Home'; // Default Brand Text
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];

      if (route.collapse) {
        const collapseActiveRoute = getActiveRoute(route.items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (route.category) {
        const categoryActiveRoute = getActiveRoute(route.items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else if (route.subroutes) {
        const subRouteActive = getActiveRoute(route.subroutes);
        if (subRouteActive !== activeRoute) {
          return subRouteActive;
        }
      } else {
        const fullPath = `${route.layout}${route.path}`;
        if (location.pathname.includes(fullPath)) {
          return route.name;
        }
      }
    }
    return activeRoute;
  };

  // Effect to update active route when the location changes
  useEffect(() => {
    setActiveRoute(getActiveRoute(routes));
    checkAuthorization();
  }, [location, routes]);

  // Function to check if the user is authorized
  const checkAuthorization = async () => {
    try {
      const response = await apiClient.get('/authcheck');

      if (response.status === 200 && response.data.authorized) {
        // If authorized, set isAuthorized to true
        setIsAuthorized(true);
      } else {
        // If not authorized, set isAuthorized to false
        setIsAuthorized(false);
      }
    } catch (error) {
      console.error('Authorization check failed:', error);
      setIsAuthorized(false); // On error, assume not authorized
    } finally {
      setLoading(false); // Stop loading state
    }
  };

  // Function to get routes dynamically
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/admin') {
        const routeElement = (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );

        // Check for subroutes
        if (route.subroutes) {
          return (
            <React.Fragment key={key}>
              {routeElement}
              {route.subroutes.map((subroute, subkey) => (
                <Route
                  path={`${subroute.path}`}
                  element={subroute.component}
                  key={subkey}
                />
              ))}
            </React.Fragment>
          );
        }

        return routeElement;
      }
      return null;
    });
  };

  // If the user is not authorized, redirect to the login page
  if (loading) {
    return null; // Or a loading spinner
  }

  if (isAuthorized === false) {
    navigate('/login'); // Redirect to login if not authorized
    return null; // Prevent further rendering
  }

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc(100% - 290px)' }}
        >
          <Portal>
            <Navbar
              onOpen={onOpen}
              logoText={'Dashboard PRO'}
              brandText={activeRoute} // Use the active route here
              fixed={fixed}
              {...rest}
            />
          </Portal>
          <Box
            mx="auto"
            p={{ base: '20px', md: '30px' }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
            <Routes>
              {/* Conditionally render /admin/dashboard when /dashboard is visited */}
              {location.pathname === '/dashboard' && (
                <Route
                  path="/dashboard"
                  element={<Navigate to="/admin/dashboard" replace />}
                />
              )}

              {/* Load routes for admin layout */}
              {getRoutes(routes)}

              {/* Default route */}
              <Route
                path="/"
                element={<Navigate to="/admin/dashboard" replace />}
              />
              {/* Catch-all route */}
              <Route path="*" element={<Navigate to="/admin/dashboard" />} />
            </Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
