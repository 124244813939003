import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  HStack,
  Text,
  Collapse,
  useColorModeValue,
} from '@chakra-ui/react';

export function SidebarLinks({ routes, onSelect = () => {} }) {
  const location = useLocation();

  const activeColor = useColorModeValue('blue.500', 'blue.500');
  const inactiveColor = useColorModeValue('gray.500', 'white');
  const activeIconColor = useColorModeValue('blue.500', 'blue.500');
  const inactiveIconColor = useColorModeValue('gray.500', 'gray.400'); // Added inactive icon color
  const textColor = useColorModeValue('gray.500', 'white');

  const [openRoute, setOpenRoute] = useState(null);

  const isActiveRoute = (routeName) => location.pathname.includes(routeName);

  const toggleSubmenu = (routeName) => {
    setOpenRoute((prev) => (prev === routeName ? null : routeName));
  };

  const handleSubmenuClick = (subroute) => {
    try {
      onSelect();
      setOpenRoute(null);
    } catch (error) {
      //console.error('Error navigating to subroute:', error);
    }
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      const { name, path, subroutes, icon } = route;
      const isActive =
        isActiveRoute(path) ||
        (subroutes && subroutes.some((sub) => isActiveRoute(sub.path)));

      if (subroutes) {
        return (
          <React.Fragment key={index}>
            <Flex
              onClick={() => toggleSubmenu(name)}
              cursor="pointer"
              py="10px"
              px="45px"
            >
              <Box
                color={isActive ? activeIconColor : inactiveIconColor} // Apply inactive icon color
                me="10px"
                mr="24px"
                aria-hidden="true"
              >
                {icon}
              </Box>
              <Text
                fontSize="lg" // Increased font size for better readability
                color={isActive ? activeColor : inactiveColor}
              >
                {name}
              </Text>
            </Flex>
            <Collapse in={openRoute === name || isActive}>
              <Box pl={6}>
                {subroutes.map((subroute, subIndex) => (
                  <NavLink
                    key={subIndex}
                    to={subroute.layout + subroute.path}
                    onClick={() => handleSubmenuClick(subroute)}
                  >
                    <HStack spacing="6px" py="8px" ps="40px">
                      <Box
                        color={
                          isActiveRoute(subroute.path)
                            ? activeIconColor
                            : inactiveIconColor
                        } // Apply inactive icon color
                        me="10px"
                      >
                        {subroute.icon}
                      </Box>
                      <Text
                        fontSize="md" // Adjusted font size for sub-links
                        color={
                          isActiveRoute(subroute.path) ? activeColor : textColor
                        }
                      >
                        {subroute.name}
                      </Text>
                    </HStack>
                  </NavLink>
                ))}
              </Box>
            </Collapse>
          </React.Fragment>
        );
      } else {
        return (
          <NavLink key={index} to={route.layout + path} onClick={onSelect}>
            <HStack py="10px" ps="45px">
              <Box
                color={
                  isActiveRoute(path) ? activeIconColor : inactiveIconColor
                } // Apply inactive icon color
                me="18px"
              >
                {icon}
              </Box>
              <Text
                fontSize="lg" // Increased font size for better readability
                color={isActiveRoute(path) ? activeColor : textColor}
              >
                {name}
              </Text>
            </HStack>
          </NavLink>
        );
      }
    });
  };

  useEffect(() => {
    const activeSubroute = routes.some((route) =>
      route.subroutes?.some((sub) => isActiveRoute(sub.path)),
    );

    if (activeSubroute) {
      setOpenRoute(null);
    }
  }, [location.pathname, routes]);

  return <Box>{createLinks(routes)}</Box>;
}

export default SidebarLinks;
