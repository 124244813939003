
import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Text,
  Skeleton,
  Button,
  Flex,
  Select,
  keyframes,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';

import ComplexTable from "views/admin/Commission/components/ViewCommission";

const sampleData = [
  {
    id: 1,
    name: 'Alice Johnson',
    email: 'alice@example.com',
    username: 'alicej',
    status: 'Approved',
    commission: '$150',
    enabled: true,
    paid: true,
  },
  {
    id: 2,
    name: 'Bob Smith',
    email: 'bob@example.com',
    username: 'bobsmith',
    status: 'Disabled',
    commission: '$100',
    enabled: false,
    paid: false,
  },
  {
    id: 3,
    name: 'Charlie Brown',
    email: 'charlie@example.com',
    username: 'charlieb',
    status: 'Error',
    commission: '$200',
    enabled: true,
    paid: false,
  },
  {
    id: 4,
    name: 'Diana Prince',
    email: 'diana@example.com',
    username: 'dianap',
    status: 'Approved',
    commission: '$250',
    enabled: true,
    paid: true,
  },
  {
    id: 5,
    name: 'Ethan Hunt',
    email: 'ethan@example.com',
    username: 'ethanh',
    status: 'Disabled',
    commission: '$0',
    enabled: false,
    paid: true,
  },
];
const shadowAnimation = keyframes`
  0% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
  50% { box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
  100% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
`;

export default function UserReports() {
  const navigate = useNavigate();
  const brandColor = useColorModeValue("white","#111c44" );
  const [tableData, setTableData] = useState(sampleData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedProductType, setSelectedProductType] = useState("");

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/api/products`, {
        params: {
          market: selectedCountry,
          product_type: selectedProductType,
          page: pageIndex,
          size: pageSize,
        },
      });
      if (response.data && response.data.users.length > 0) {
        setTableData(response.data.users);
        setTotalPages(Math.ceil(response.data.total / pageSize));
      } else {
        setTableData(sampleData);
        setTotalPages(Math.ceil(sampleData.length / pageSize));
      }
    } catch (err) {
      //console.error("Error fetching data:", err);
      setTableData(sampleData);
      setTotalPages(Math.ceil(sampleData.length / pageSize));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, pageSize, selectedCountry, selectedProductType]);

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid columns={1} spacing={4}>
          {[...Array(7)].map((_, index) => (
            <Box key={index} height="50px" borderRadius="10px" animation={`${shadowAnimation} 1.5s infinite`} overflow="hidden">
              <Skeleton height="100%" borderRadius="10px" fadeDuration={1} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    );
  }

  if (error) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} textAlign="center">
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  if (tableData.length === 0) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} textAlign="center">
        <Text>No data available.</Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid>
        <Flex
          px="25px"
         bg= {brandColor}// White in light mode, dark gray in dark mode
          justifyContent="space-between"
          align="center"
          gap={2}
          borderTopLeftRadius="15px"
          borderTopRightRadius="15px"
        >
          <Text fontSize="22px" fontWeight="200" mt="15px">
            Overview Commission
          </Text>
          <Flex  mt="15px" mb="0px" justifyContent="space-between" align="center" gap={1}>
            <Flex align="center" gap={1}>
            <Select
                value={selectedProductType}
                onChange={(e) => {
                  setSelectedProductType(e.target.value);
                  setPageIndex(0);
                }}
                width="90px"
                lineHeight="100%"
                borderRadius="10px"
                ml="10px"
              >
               <option value="" disabled selected>Role</option>
  <option value="0">Admins</option>
  <option value="1">Pmms</option>
  <option value="2">Pms</option>
              </Select>

              <Select
                value={selectedProductType}
                onChange={(e) => {
                  setSelectedProductType(e.target.value);
                  setPageIndex(0);
                }}
                width="125px"
                lineHeight="100%"
                borderRadius="10px"
                ml="10px"
              >
               <option value="" disabled selected>Select Type</option>
  <option value="0">Paid</option>
  <option value="1">Unpaid</option>
  <option value="2">Partially Paid</option>
              </Select>

              {/* <Select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          width="80px"
                lineHeight="100%"
                borderRadius="10px"
                ml="10px"
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </Select> */}
            </Flex>
          </Flex>
        </Flex>

        <ComplexTable tableData={tableData} phoneNumber="1234567890" pageSize={pageSize} />

        <Flex justifyContent="space-between" align="center" p="20px">
          <Button onClick={() => setPageIndex((old) => Math.max(0, old - 1))} disabled={pageIndex === 0}>
            Previous
          </Button>
          <Text>
            Page {pageIndex + 1} of {totalPages}
          </Text>
          <Button onClick={() => setPageIndex((old) => Math.min(totalPages - 1, old + 1))} disabled={pageIndex >= totalPages - 1}>
            Next
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
