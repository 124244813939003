import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [
        {
          name: 'Traffic',
          data: [30, 40, 35, 50, 49, 60, 70], // Sample data for the bar chart
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 240,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], // Categories for the x-axis
        },
        title: {
          text: 'Weekly Traffic',
          align: 'center',
        },
        colors: ['#008FFB'], // Bar color
      },
    };
  }

  componentDidMount() {
    this.setState({
      chartData: this.props.chartData || this.state.chartData,
      chartOptions: this.props.chartOptions || this.state.chartOptions,
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default ColumnChart;
