import {
  Box,
  Flex,
  Button,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Card from 'components/card/Card';
import React, { useState } from 'react';
import { MdCancel, MdCheckCircle, MdOutlineError } from 'react-icons/md';
import Swal from 'sweetalert2';

const columnHelper = createColumnHelper();

export default function ComplexTable({ tableData }) {
  const [sorting, setSorting] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('email', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('username', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Username
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('status', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              info.getValue() === 'Approved'
                ? 'green.500'
                : info.getValue() === 'Disabled'
                ? 'red.500'
                : info.getValue() === 'Error'
                ? 'orange.500'
                : null
            }
            as={
              info.getValue() === 'Approved'
                ? MdCheckCircle
                : info.getValue() === 'Disabled'
                ? MdCancel
                : info.getValue() === 'Error'
                ? MdOutlineError
                : null
            }
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('commission', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Commission
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="lg" fontWeight="200">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          textAlign="center"
        >
          Action Buttons
        </Text>
      ),
      cell: (info) => {
        const isEnabled = info.row.original.isEnabled;

        return (
          <HStack spacing={2} justify="center">
            <Button
              color={isEnabled ? 'red.500' : 'green.500'}
              borderColor={isEnabled ? 'red.500' : 'green.500'}
              variant="outline"
              width="100px"
              borderRadius="full"
              onClick={() =>
                Swal.fire({
                  title: `Are you sure you want to ${
                    isEnabled ? 'unpay' : 'pay'
                  } this user?`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes',
                }).then((result) => {
                  if (result.isConfirmed) {
                    console.log(
                      `${isEnabled ? 'Unpaid' : 'Paid'}:`,
                      info.row.original,
                    );
                    // Handle the action logic here
                  }
                })
              }
            >
              {isEnabled ? 'Unpayed' : 'Payed'}
            </Button>
          </HStack>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      borderTopLeftRadius="0px"
      borderTopRightRadius="0px"
      sx={{
        overflowX: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    pe="10px"
                    borderColor={borderColor}
                    cursor="pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="center"
                  >
                    <Flex
                      justifyContent="center"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      <Box>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </Box>
                      <Box ml={2}>
                        {header.column.getIsSorted()
                          ? header.column.getIsSorted() === 'asc'
                            ? '↑'
                            : '↓'
                          : null}
                      </Box>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={`${cell.id}_${row.id}`}
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    borderColor="transparent"
                    textAlign="center"
                    verticalAlign="middle"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size={{ base: 'md', sm: 'lg' }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="md"
          boxShadow="lg"
          p={2}
          mt={{ base: 'center', sm: '140' }}
        >
          <ModalHeader textAlign="center" fontSize="xl" fontWeight="bold">
            Image Preview
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={6}
          >
            {/* Add your image preview logic here if needed */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
}
