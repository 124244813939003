// Chakra imports
import {
  Text,
  useColorModeValue,
  Button,
  FormControl,
  Input,
  Textarea,
  Flex,
  Box,
} from '@chakra-ui/react';
import React from 'react';
import Card from 'components/card/Card.js';
import { v4 as uuidv4 } from 'uuid'; // Import the uuid function
import apiClient from '../../../../authclient/authclient';
import { useNavigate, useLocation } from 'react-router-dom';

export default function UpdatePassword() {
  const navigate = useNavigate();
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  // State for form data
  const [formData, setFormData] = React.useState({
    oldPassword: '',
    newPassword: '',
    repeatPassword: '',
    reason: '', // Added reason field
  });

  // State for error and success messages
  const [errorMsg, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  // Generate unique IDs for input fields
  const uniqueIds = {
    oldPasswordId: uuidv4(),
    newPasswordId: uuidv4(),
    repeatPasswordId: uuidv4(),
    reasonId: uuidv4(), // Unique ID for reason field
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Required fields check for password-related fields and reason
    const requiredFields = [
      'oldPassword',
      'newPassword',
      'repeatPassword',
      'reason',
    ];

    // Check if all fields are filled
    for (const field of requiredFields) {
      if (!formData[field]) {
        setError(
          `${field.replace(/([A-Z])/g, ' $1').toLowerCase()} is required.`,
        ); // Improved field name readability
        return;
      }
    }

    // Additional validation for password matching
    if (formData.newPassword !== formData.repeatPassword) {
      setError('New password and repeat password do not match.');
      return;
    }

    // Get the userId from localStorage
    const userId = localStorage.getItem('userId');

    // If userId is not available, handle the case
    if (!userId) {
      setError('User ID is missing.');
      return;
    }

    // Prepare the data to be sent
    const payload = {
      userId,
      oldPassword: formData.oldPassword,
      newPassword: formData.newPassword,
      repeatPassword: formData.repeatPassword,
      reason: formData.reason,
    };

    try {
      // Send the data via PUT request (using Axios, no need for .json() like fetch)
      const response = await apiClient.put('update-password', payload);

      // Handle server response by checking the response data directly
      if (response.data.message === 'Password update successful!') {
        setSuccess('Password updated successfully!');
        setError(''); // Clear previous error messages
      } else {
        setError(response.data.message || 'An error occurred.');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        navigate('/login'); // Redirect to login if 403 Forbidden error occurs
      }
      //console.error('Error submitting form:', error);

      // Handle Axios errors
      if (error.response) {
        // Server responded with an error status (4xx, 5xx)
        setError(
          error.response.data.message || 'An error occurred on the server.',
        );
      } else if (error.request) {
        // No response received from the server
        setError('No response from the server.');
      } else {
        // Any other errors (e.g., network or setup errors)
        setError('An error occurred while processing your request.');
      }
    }
  };

  return (
    <Card>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="0px"
        mb="14px"
      >
        Update Password
      </Text>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="xl"
        mt="10px"
        mb="30px"
      >
        Your password must be more than 6 characters long, should contain at
        least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControl>
          {/* Error and Success Messages */}
          {errorMsg && (
            <Text
              fontSize="sm"
              mb="8px"
              color="#721c24"
              backgroundColor="#f8d7da"
              borderColor="#f5c6cb"
              position="relative"
              padding=".75rem 1.25rem"
              border="1px solid transparent"
              borderRadius=".25rem"
            >
              {errorMsg}
            </Text>
          )}
          {success && (
            <Text
              fontSize="sm"
              mb="8px"
              color="#155724"
              backgroundColor="#d4edda"
              borderColor="#c3e6cb"
              position="relative"
              padding=".75rem 1.25rem"
              border="1px solid transparent"
              borderRadius=".25rem"
            >
              {success}
            </Text>
          )}

          <Flex direction={{ base: 'column', md: 'row' }} mb="24px">
            <Box flex="1" pr={{ md: '12px' }}>
              {/* Old Password Field */}
              <Input
                color="gray.400"
                mb="25px"
                type="password"
                id={uniqueIds.oldPasswordId} // Unique ID
                name="oldPassword"
                required
                value={formData.oldPassword}
                onChange={handleChange}
                placeholder="Old Password"
                autoComplete="current-password" // Added autoComplete
              />

              {/* New Password Field */}
              <Input
                color="gray.400"
                mb="25px"
                type="password"
                id={uniqueIds.newPasswordId} // Unique ID
                name="newPassword"
                required
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="New Password"
                autoComplete="new-password" // Added autoComplete
              />

              {/* Repeat New Password Field */}
              <Input
                color="gray.400"
                mb="25px"
                type="password"
                id={uniqueIds.repeatPasswordId} // Unique ID
                name="repeatPassword"
                required
                value={formData.repeatPassword}
                onChange={handleChange}
                placeholder="Repeat New Password"
                autoComplete="new-password" // Added autoComplete
              />

              {/* Reason Field */}
              <Textarea
                color="gray.400"
                mb="25px"
                id={uniqueIds.reasonId} // Unique ID
                name="reason"
                required
                value={formData.reason}
                onChange={handleChange}
                placeholder="Reason for Changing Password"
                rows={4}
              />
            </Box>
          </Flex>

          {/* Submit Button */}
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            color="black"
            background="rgb(231, 231, 231)"
            border="none"
            borderRadius="8px"
            outline="none"
            cursor="pointer"
            transition="0.3s ease-in-out"
            boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
            w="100%"
            h="50px"
            mb="24px"
            _hover={{
              bg: 'linear-gradient(135deg, #00B74D 0%, #009E4B 100%)',
              color: 'white',
            }}
            type="submit"
          >
            Update Password
          </Button>
        </FormControl>
      </form>
    </Card>
  );
}
