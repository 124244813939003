import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Image,
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  keyframes,
} from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import DefaultAuth from 'layouts/auth/Default';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import logo from '../../../assets/img/auth/logo.ico';
import axios from 'axios';
const SignIn = () => {
  const textColorSecondary = 'gray.400';
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState({ error: '', success: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevShow) => !prevShow);
  };

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setMessage({ error: '', success: '' });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    if (!email) {
      setMessage({ error: 'Email is required', success: '' });
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setMessage({ error: 'Please enter a valid email address.', success: '' });
      setLoading(false);
      return;
    }

    if (!password) {
      setMessage({ error: 'Password is required', success: '' });
      setLoading(false);
      return;
    }

    try {
      // Dynamically set the base URL based on the hostname
      const baseUrl =
        window.location.hostname === 'localhost'
          ? 'http://localhost:3001/login'
          : 'http://192.168.100.35:3001/login';
    
      // Make the POST request to the login endpoint
      const response = await axios.post(baseUrl, {
        email,
        password,
      }, {
        withCredentials: true, // Allow cookies to be sent with the request
      });
     
      // Store the user ID in localStorage (Optional - for front-end user access)
      localStorage.setItem('userId', response.data.user.id);
      localStorage.setItem('userRole', response.data.user.role);

      // Set the success message (optional, for feedback)
      setMessage({ success: response.data.message, error: '' });
    
      // Navigate to the admin page upon successful login
      navigate('/admin');
    } catch (error) {
      // Handle error: check if error.response is available to get error details
      const errorMessage = error.response
        ? error.response.data.message // Error message from the server
        : 'An error occurred. Please try again.'; // Generic fallback error message
    
      // Set error message state
      setMessage({ error: errorMessage, success: '' });
    } finally {
      // Ensure loading state is turned off once the request completes
      setLoading(false);
    }
    
  };

  const loadingAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

  // Keyframes for loading dots animation

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: '30px', md: '60px' }}
        px={{ base: '25px', md: '0px' }}
        mt={{ base: '40px', md: '14vh' }}
        flexDirection="column"
      >
        {/* Loading Overlay */}
        {loading && (
          <Box
            position="fixed"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0.8)" // Dark background
            zIndex="999"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              {' '}
              {/* Reduced margin-bottom */}
              {[
                'red.500',
                'blue.500',
                'green.500',
                'yellow.500',
                'purple.500',
              ].map((color, index) => (
                <Box
                  key={index}
                  bg={color}
                  borderRadius="50%"
                  width="15px"
                  height="15px"
                  marginX="5px"
                  animation={`${loadingAnimation} 1s infinite ease-in-out`}
                  animationdelay={`${index * 0.2}s`} // Stagger the animations
                />
              ))}
            </Box>

            <Text
              fontSize="3xl"
              color="white"
              mt={1} // Reduced margin-top
              fontWeight="bold"
              textAlign="center"
              textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
              letterSpacing="1px"
            >
              Loading...
            </Text>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          h="30vh"
          w="100%"
        >
          <Image
            src={logo}
            alt="Logo"
            mb="20px"
            width="100px"
            borderRadius="50%"
            boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
            maxWidth="100%"
            height="auto"
          />
          <Text
            mb="36px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            textAlign="center"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>

        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '370px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          mb={{ base: '20px', md: 'auto' }}
        >
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>

          <form onSubmit={handleSubmit}>
            <FormControl>
              {message.error && (
                <Text
                  fontSize="sm"
                  mb="8px"
                  color="#721c24"
                  backgroundColor="#f8d7da"
                  border="1px solid transparent"
                  borderRadius=".25rem"
                  padding=".75rem 1.25rem"
                  marginBottom="1rem"
                >
                  {message.error}
                </Text>
              )}
              {message.success && (
                <Text
                  fontSize="sm"
                  mb="8px"
                  color="#155724"
                  backgroundColor="#d4edda"
                  border="1px solid transparent"
                  borderRadius=".25rem"
                  padding=".75rem 1.25rem"
                  marginBottom="1rem"
                >
                  {message.success}
                </Text>
              )}
              <Input
                id="email-field"
                value={email}
                onChange={handleInputChange(setEmail)}
                isRequired
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="Email@gmail.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
                autoComplete="username" // Add this line
              />
              <InputGroup size="md">
                <Input
                  id="password-field"
                  value={password}
                  onChange={handleInputChange(setPassword)}
                  isRequired
                  fontSize="sm"
                  placeholder="Password Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={showPassword ? 'text' : 'password'}
                  variant="auth"
                  boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
                  autoComplete="current-password" // or "new-password" depending on the context
                />

                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="700"
                color="black"
                background="rgb(231, 231, 231)"
                border="none"
                borderRadius="8px"
                boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
                w="100%"
                h="50px"
                mb="24px"
                _hover={{ bg: '#003042', color: 'white' }}
                type="submit"
              >
                Sign In
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;
