import React from 'react';

// Chakra imports
import { Flex } from '@chakra-ui/react';

// Custom components

import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      {/* <HSeparator /> */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '20px 0',
        }}
      >
        <h1
          style={{
            fontWeight: 'bold',
            fontSize: '24px',
            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
          }}
        >
          {/* NABEEL#2004 */}
          MERCANTILE
        </h1>
      </div>

      <HSeparator />
    </Flex>
  );
}

export default SidebarBrand;
