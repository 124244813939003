// Chakra imports
import {
  Text,
  useColorModeValue,
  Button,
  FormControl,
  Input,
  Flex,
  Box,
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card.js';
import React, { useEffect, useState } from 'react';
import CircularWatch from './watch';
import apiClient from '../../../../authclient/authclient';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
export default function AddCity() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [formData, setFormData] = useState({
    cityName: '',
    country: '',
    instruction: [],
  });
  const [productId, setProductId] = useState('');
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    };

    updateTime();
    const interval = setInterval(updateTime, 1000); // Update every second

    return () => clearInterval(interval); // Clean up on unmount
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const userId = localStorage.getItem('userId');

    if (!userId) {
      //console.error('Error fetching userID. Please log in again!');
      setError('Please log in to submit the reservation.');
      return;
    }

    setLoading(true); // Set loading state to true when starting the request

    try {
      const response = await apiClient.post(`createReservation`, {
        productId,
        userId, // Send productId as data in the request body
      });

      // console.log('Response data:', response.data); // Handle the response if needed
      setSuccess('Reservation successfully added!');
      Swal.fire({
        icon: 'success', // Set the icon to 'success'
        title: 'Success', // Optional title for the alert
        text: 'Reservation successfully added!', // The message from setSuccess or a custom message
        showConfirmButton: true, // Show the confirm button
        confirmButtonText: 'OK', // Customize the button text if you want
      });
    } catch (error) {
      // Handle different errors
      if (error.response && error.response.status === 403) {
        // Redirect user to login if 403 (Forbidden) error occurs
        Swal.fire({
          icon: 'error',
          title: 'Unauthorized',
          text: 'You need to log in to complete the reservation.',
          confirmButtonText: 'Go to Login',
        }).then(() => {
          navigate('/login'); // Redirect to login page after the user confirms
        });
      } else {
        // Show general error message for other types of errors
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'There was an error while adding the reservation.',
          confirmButtonText: 'Try Again',
        });
      }
      console.error('Error submitting data:', error);
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };

  const [secondRatio, setSecondRatio] = useState(0);
  const [minuteRatio, setMinuteRatio] = useState(0);
  const [hourRatio, setHourRatio] = useState(0);

  // Clock update for CircularWatch with timezone
  useEffect(() => {
    const updateClock = () => {
      const now = moment().tz('Asia/Karachi');
      const seconds = now.seconds();
      const minutes = now.minutes();
      const hours = now.hours() % 12;

      setSecondRatio(seconds / 60);
      setMinuteRatio((seconds + minutes * 60) / 3600);
      setHourRatio((minutes + hours * 60) / 720);
    };

    const interval = setInterval(updateClock, 1000);
    updateClock();

    return () => clearInterval(interval);
  }, []);

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mt="0px"
            mb="14px"
          >
            Add Reservation
          </Text>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="xl"
            mt="10px"
            mb="10px"
          >
            Please enter the correct product ID to request a reservation.
          </Text>
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="xl"
            mb="30px"
          >
            <span style={{ color: 'red' }}> Note: </span>&nbsp; The reserved
            time is 1:00 PM.
          </Text>
          {/* Display Current Local Time */}
          <Text color={textColorPrimary} fontSize="lg" mb="20px">
            Current Time: {currentTime}
          </Text>
        </div>
        <div
          style={{
            marginLeft: '10px',
            marginRight: '10%', // Use 'marginRight' instead of 'me'
            marginBottom: '20px',
            display: window.innerWidth < 650 ? 'none' : 'block', // Change this threshold as needed
          }}
        >
          <CircularWatch
            secondRatio={secondRatio}
            minuteRatio={minuteRatio}
            hourRatio={hourRatio}
          />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <FormControl>
          {errorMsg && (
            <Text
              fontSize="sm"
              color="#721c24"
              bg="#f8d7da"
              borderColor="#f5c6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {errorMsg}
            </Text>
          )}
          {success && (
            <Text
              fontSize="sm"
              color="#155724"
              bg="#d4edda"
              borderColor="#c3e6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {success}
            </Text>
          )}
          {loading && (
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="rgba(0, 0, 0, 0.8)"
              zIndex="999"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {[
                  'red.500',
                  'blue.500',
                  'green.500',
                  'yellow.500',
                  'purple.500',
                ].map((color, index) => (
                  <Box
                    key={index}
                    bg={color}
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    marginX="5px"
                    animation="loadingAnimation 1s infinite ease-in-out"
                    animationdelay={`${index * 0.2}s`}
                  />
                ))}
              </Box>
              <Text
                fontSize="3xl"
                color="white"
                mt={1}
                fontWeight="bold"
                textAlign="center"
                textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
                letterSpacing="1px"
              >
                Wait...
              </Text>
            </Box>
          )}

          <Flex direction={{ base: 'column', md: 'row' }} mb="24px">
            <Box flex="1" pr={{ md: '12px' }}>
              <Input
                color="gray.400"
                name="productId"
                placeholder="Enter Product ID"
                onChange={(e) => setProductId(e.target.value)}
                mb="4"
              />
            </Box>
          </Flex>

          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="700"
            color="black"
            background="rgb(231, 231, 231)"
            border="none"
            borderRadius="8px"
            outline="none"
            cursor="pointer"
            transition="0.3s ease-in-out"
            boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
            w="100%"
            h="50px"
            mb="24px"
            _hover={{
              bg: 'linear-gradient(135deg, #00B74D 0%, #009E4B 100%)',
              color: 'white',
            }}
            type="submit"
          >
            Add Reservation
          </Button>
        </FormControl>
      </form>
    </Card>
  );
}
