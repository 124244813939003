import {
  Box,
  SimpleGrid,
  Skeleton,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import React, { useEffect, useState } from "react";
import DailyTraffic from "views/admin/dashboard/components/DailyTraffic";
import PieCard from "views/admin/dashboard/components/PieCard";
import apiClient from "../../../authclient/authclient";
import { useNavigate } from 'react-router-dom';

export default function UserReports() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    totalUser: 0,
    totalAdmin: 0,
    totalSeller: 0,
    totalPm: 0,
    activePm: 0,
    blockedPm: 0,
    totalProducts: 0,
    activeProducts: 0,
    disabledProducts: 0,
    totalOrders: 0,
    ordered: 0,
    reviewed: 0,
    delivered: 0,
    refunded: 0,
    onHold: 0,
    deleted: 0,
    completed: 0,
    cancelled: 0,
    warnedPm: 0,
    totalReservation: 0, 
    userReservation: 0,  
    totalRefundedCommission: 0,
    weeklyLoginCounts: [],
  });
   
   const calculatePercentage = (part, total) => {
    return total === 0 ? 0 : ((part / total) * 100).toFixed(2); 
  };

  const {
    totalUser, totalAdmin, totalSeller, totalPm, activePm, blockedPm,
    totalProducts, activeProducts, disabledProducts,
    totalOrders, ordered, reviewed, delivered, refunded, onHold, deleted, completed, cancelled, warnedPm
    ,totalReservation, userReservation,totalRefundedCommission,  weeklyLoginCounts  } = dashboardData;

  
  
  const AdminPercentage = calculatePercentage(totalAdmin, totalUser);
  const SellerPercentage = calculatePercentage(totalSeller, totalUser);
  const PmsPercentage = calculatePercentage(totalPm, totalUser);

  const activePmPercentage = calculatePercentage(activePm, totalPm);
  const blockedPmPercentage = calculatePercentage(blockedPm, totalPm);
  const activeProductsPercentage = calculatePercentage(activeProducts, totalProducts);
  const disabledProductsPercentage = calculatePercentage(disabledProducts, totalProducts);
  const orderedPercentage = calculatePercentage(ordered, totalOrders);
  const reviewedPercentage = calculatePercentage(reviewed, totalOrders);
  const deliveredPercentage = calculatePercentage(delivered, totalOrders);
  const refundedPercentage = calculatePercentage(refunded, totalOrders);
  const onHoldPercentage = calculatePercentage(onHold, totalOrders);
  const deletedPercentage = calculatePercentage(deleted, totalOrders);
  const completedPercentage = calculatePercentage(completed, totalOrders);
  const cancelledPercentage = calculatePercentage(cancelled, totalOrders);
  const warnedPmPercentage = calculatePercentage(warnedPm, totalPm);
  const reservationPercentage = calculatePercentage(userReservation, totalReservation);


  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const storedRole = localStorage.getItem('userRole');
    if (storedRole) {
      setUserRole(storedRole);
    }
    fetchdetails();
  }, []);


  const fetchdetails = async () => {
    try {
      const response = await apiClient.get('/dashboard');
      setDashboardData(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        navigate('/login');
      }
      //console.error("Error fetching dashboard data:", error);
    }finally{
      setLoading(false);
    }
  };



  // Keyframes for shadow animation
  const pulse = keyframes`
    0% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
    50% { box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); }
    100% { box-shadow: 0 0 0 rgba(0, 0, 0, 0.1); }
  `;



  const pieChartData = [
  { name: 'Ordered', value: dashboardData.ordered },
  { name: 'Reviewed', value: dashboardData.reviewed },
  { name: 'Delivered', value: dashboardData.delivered },
  { name: 'Refunded', value: dashboardData.refunded },
  { name: 'On Hold', value: dashboardData.onHold },
  { name: 'Deleted', value: dashboardData.deleted },
  { name: 'Completed', value: dashboardData.completed },
  { name: 'Cancelled', value: dashboardData.cancelled },
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      
{["SuperAdmin", "Admin"].includes(userRole) && (
      <SimpleGrid columns={{ base: 1, md: 2, lg: 4, "2xl": 6 }} gap='20px' mb='20px'>
        {loading ? (
          [...Array(4)].map((_, index) => (
            <Skeleton key={index} height="100px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>

            <MiniStatistics growth='+100%' name='Users' value={dashboardData.totalUser} />
            <MiniStatistics growth={`+${AdminPercentage}%`} name='Admin' value={dashboardData.totalAdmin} />
            <MiniStatistics growth={`+${SellerPercentage}%`} name='Sellers' value={dashboardData.totalSeller} />
            <MiniStatistics growth={`+${PmsPercentage}%`} name='PMs' value={dashboardData.totalPm} />
          </>
        )}
      </SimpleGrid>
    )}
{["SuperAdmin", "Admin","Seller"].includes(userRole) && (
      <SimpleGrid columns={{ base: 1, md: 2, lg: 5, "2xl": 6 }} gap='20px' mb='20px'>
        {loading ? (
          [...Array(5)].map((_, index) => (
            <Skeleton key={index} height="100px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>
            <MiniStatistics  growth={`+100%`}  name='My Products' value={dashboardData.totalProducts} />
            <MiniStatistics  growth={`+${activeProductsPercentage}%`}  name='Active Products' value={dashboardData.activeProducts} />
            <MiniStatistics  growth={`+${disabledProductsPercentage}%`}  name='Disabled Products' value={dashboardData.disabledProducts} />
            <MiniStatistics growth={`+${reservationPercentage}%`} name='Reservations'  value={
    ["Seller", "Pm"].includes(userRole)
      ? dashboardData.userReservation
      : dashboardData.totalReservation
  } /> 
            <MiniStatistics  growth={`+00%`}  name='Premium Products' value="-"  />

          </>
        )}
      </SimpleGrid>
       )}

      <SimpleGrid columns={{ base: 1, md: 2, lg: 4, "2xl": 6 }} gap='20px' mb='20px'>
        {loading ? (
          [...Array(4)].map((_, index) => (
            <Skeleton key={index} height="100px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>
            <MiniStatistics   growth={`+${completedPercentage}%`}  name='Completed' value={dashboardData.completed} />
            <MiniStatistics   growth={`+${cancelledPercentage}%`}  name='Cancelled'value={dashboardData.cancelled} />
            <MiniStatistics  growth={`+${orderedPercentage}%`}  name='Ordered' value={dashboardData.ordered}  />
            <MiniStatistics   growth={`+${orderedPercentage}%`}  name='Refunded' value={dashboardData.refunded} />
         
      
          </>
        )}
      </SimpleGrid>
    

      <SimpleGrid columns={{ base: 1, md: 2, lg: 5, "2xl": 6 }} gap='20px' mb='20px'>
        {loading ? (
          [...Array(5)].map((_, index) => (
            <Skeleton key={index} height="100px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>
                <MiniStatistics   growth={`+${reviewedPercentage}%`}  name='Reviewed' value={dashboardData.reviewed}  />
            <MiniStatistics   growth={`+${deliveredPercentage}%`}  name='Review Submitted for Refund' value={dashboardData.delivered}  />
            <MiniStatistics   growth={`+${deletedPercentage}%`}  name='Review Deleted' value={dashboardData.deleted}  />
            <MiniStatistics   growth={`+${onHoldPercentage}%`}  name='On Hold' value={dashboardData.onHold}  />
            <MiniStatistics   growth={`+100%`}  name='Commissioned'value={dashboardData.totalRefundedCommission} />
         
          </>
        )}
      </SimpleGrid>
      {["SuperAdmin", "Admin"].includes(userRole) && (
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap='20px' mb='20px'>
        {loading ? (
          [...Array(3)].map((_, index) => (
            <Skeleton key={index} height="100px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>

            <MiniStatistics  growth={`+${activePmPercentage}%`}  name='Active PMs' value={dashboardData.activePm} />
            <MiniStatistics  growth={`+${blockedPmPercentage}%`}  name='Blocked PMs' value={dashboardData.blockedPm} />
            <MiniStatistics  growth={`+00%`}  name='Online Users' value='-' />

          </>
        )}
      </SimpleGrid>
 )}
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
        {loading ? (
          [...Array(2)].map((_, index) => (
            <Skeleton key={index} height="300px" borderRadius="10px" fadeDuration={1} />
          ))
        ) : (
          <>
            {/* <TotalSpent
              previousMonthData={{ profit: [10, 10, 10, 10, 10, 10] }}
              currentMonthData={{ revenue: [10, 200, 300, 400, 500, 600], profit: [50, 70, 80, 90, 100, 120] }}
            /> */}
           <DailyTraffic data={weeklyLoginCounts} />
            <PieCard pieChartData={pieChartData} />
          </>
        )}
      </SimpleGrid>

      
    </Box>
  );
}
