import {
  Text,
  useColorModeValue,
  Button,
  Textarea,
  FormControl,
  Icon,
  Input,
  FormLabel,
  InputGroup,
  InputRightElement,
  Box,
  Grid,
  Flex,
  keyframes,
  Select,
} from '@chakra-ui/react';
import Card from 'components/card/Card.js';
import React, { useEffect, useState } from 'react';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Signup() {
  const textplaceholdercolor = useColorModeValue('black', 'white');
  const textColor = useColorModeValue('black', 'white');

  const loadingAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);

  if (userRole !== 'SuperAdmin' && userRole !== 'Admin') {
    navigate('/admin/dashboard');
  }

  const [name, setName] = useState('');
  const [userName, setUsername] = useState('');
  const [gender, setGender] = useState('');
  const [role, setRole] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccount] = useState('');

  const [profilePicUrl, setProfilePicUrl] = useState('');
  const [frontPicUrl, setFrontPicUrl] = useState('');
  const [backPicUrl, setBackPicUrl] = useState('');

  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const togglePasswordVisibility = () => setShow(!show);

  const handleFileUpload = async (event, type) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setError('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('key', '0c7a84592216317dc484e9758f6d7a23'); // Replace with your ImgBB API key
    formData.append('image', selectedFile);

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        'https://api.imgbb.com/1/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const displayUrl = response.data.data.display_url;
      switch (type) {
        case 'profilePic':
          setProfilePicUrl(displayUrl);
          break;
        case 'frontId':
          setFrontPicUrl(displayUrl);
          break;
        case 'backId':
          setBackPicUrl(displayUrl);
          break;
        default:
          setError('Unknown upload type.');
      }
    } catch (error) {
      setError(`Error uploading ${type}. Please try again.`);
      setSuccess('');
    } finally {
      setLoading(false); // Ensure loading is reset
    }
  };

  const CheckInputField = (inputName, value) => {
    if (!value) {
      setError(`${inputName} is required.`);
      setSuccess('');
      return false; // Return false if the field is empty
    }
    return true; // Return true if the field is valid
  };

  const signup = async (event) => {
    event.preventDefault();

    if (
      !CheckInputField('Name', name) ||
      !CheckInputField('User Name', userName) ||
      !CheckInputField('Gender', gender) ||
      !CheckInputField('Role', role) ||
      !CheckInputField('Address', address) ||
      !CheckInputField('Email', email) ||
      !CheckInputField('Password', password) ||
      !CheckInputField('Account Number', accountNumber) ||
      !CheckInputField('Bank Name', bankName)
    ) {
      return;
    }

    try {
      const response = await apiClient.post('createUser', {
        name,
        userName,
        gender,
        role,
        address,
        phone,
        email,
        password,
        profilePicUrl,
        frontPicUrl,
        backPicUrl,
        bankName,
        accountNumber,
      });

      setSuccess(response.data.message);
      setError('');
    } catch (error) {
      const serverError =
        error.response?.data?.message || 'An error occurred during signup.';
      setError(serverError);
    }
  };

  return (
    <Card>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="0px"
        mb="10px"
      >
        Add New User
      </Text>
      <form onSubmit={signup}>
        <FormControl>
          {errorMsg && (
            <Text
              fontSize="sm"
              color="#721c24"
              bg="#f8d7da"
              borderColor="#f5c6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {errorMsg}
            </Text>
          )}
          {success && (
            <Text
              fontSize="sm"
              color="#155724"
              bg="#d4edda"
              borderColor="#c3e6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {success}
            </Text>
          )}

          {loading && (
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="rgba(0, 0, 0, 0.8)"
              zIndex="999"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {[
                  'red.500',
                  'blue.500',
                  'green.500',
                  'yellow.500',
                  'purple.500',
                ].map((color, index) => (
                  <Box
                    key={index}
                    bg={color}
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    marginX="5px"
                    animation={`${loadingAnimation} 1s infinite ease-in-out`}
                    animationdelay={`${index * 0.2}s`}
                  />
                ))}
              </Box>
              <Text
                fontSize="3xl"
                color="white"
                mt={1}
                fontWeight="bold"
                textAlign="center"
                textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
                letterSpacing="1px"
              >
                File Uploading...
              </Text>
            </Box>
          )}

          <Flex direction={{ base: 'column', md: 'row' }} mb="24px" mt="20px">
            <Box flex="1" pr={{ md: '12px' }}>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  id="name"
                  placeholder="Name"
                  value={name}
                  width={{ base: '100%', md: '350px' }}
                  onChange={(e) => setName(e.target.value)}
                  mb="24px"
                  placeholdercolor={textplaceholdercolor}
                  color={textColor}
                  required
                />
              </Grid>

              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  id="username"
                  placeholder="Username"
                  value={userName}
                  onChange={(e) => setUsername(e.target.value)}
                  mb="24px"
                  placeholdercolor={textplaceholdercolor}
                  color={textColor}
                  required
                />
                <Input
                  id="email"
                  placeholder="Email"
                  type="email"
                  width={{ base: '100%', md: '260px' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  mb="24px"
                  placeholdercolor={textplaceholdercolor}
                  color={textColor}
                  required
                  autoComplete="email" // Added autoComplete attribute
                />
              </Grid>

              <InputGroup size="md">
                <Input
                  id="password"
                  placeholder="Password Min. 8 characters"
                  type={show ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  mb="24px"
                  placeholdercolor={textplaceholdercolor}
                  color={textColor}
                  required
                  autoComplete="new-password" // Added autoComplete attribute
                />
                <InputRightElement>
                  <Icon
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>

              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Select
                  id="gender"
                  placeholder="Select Gender"
                  value={gender}
                  width={{ base: '100%', md: '280px' }}
                  onChange={(e) => setGender(e.target.value)}
                  mb="24px"
                  color={textColor}
                  required
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Select>

                <Select
                  id="role"
                  placeholder="Select Role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  mb="24px"
                  color={textColor}
                  required
                >
                  {userRole === 'SuperAdmin' && (
                    <>
                      <option value="SuperAdmin">SuperAdmin</option>
                      <option value="Admin">Admin</option>
                    </>
                  )}

                  <option value="Seller">Seller</option>
                  <option value="Pm">Pm</option>
                </Select>
              </Grid>

              <Input
                id="profilePic-upload" // Unique ID
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'profilePic')}
                mb="24px"
                sx={{
                  height: 'auto',
                  padding: '.4375rem .75rem',
                  fontSize: '.8125rem',
                  lineHeight: 1.5,
                  border: '1px solid #e1e5eb',
                  fontWeight: 300,
                  willChange: 'border-color, box-shadow',
                  borderRadius: '.25rem',
                  boxShadow: 'none',
                  transition:
                    'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                }}
                required
              />
              <Input
                id="phone"
                placeholder="Phone Number"
                type="tel"
                value={phone}
                onChange={(e) => setPhoneNumber(e.target.value)}
                mb="24px"
                placeholdercolor={textplaceholdercolor}
                color={textColor}
                required
              />
              <Textarea
                id="address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                mb="24px"
                h="118px"
                placeholdercolor={textplaceholdercolor}
                color={textColor}
                required
              />
            </Box>

            <Box flex="1" pl={{ md: '12px' }}>
              <FormLabel mb="8px">Upload Front of ID Card</FormLabel>
              <Input
                id="frontId-upload" // Unique ID
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'frontId')}
                mb="24px"
                style={{
                  height: 'auto',
                  padding: '.4375rem .75rem',
                  fontSize: '.8125rem',
                  lineHeight: 1.5,
                  border: '1px solid #e1e5eb',
                  fontWeight: 300,
                  willChange: 'border-color, box-shadow',
                  borderRadius: '.25rem',
                  boxShadow: 'none',
                  transition:
                    'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                }}
                required
              />
              <FormLabel mb="8px">Upload Back of ID Card</FormLabel>

              <Input
                id="backId-upload" // Unique ID
                type="file"
                accept="image/*"
                onChange={(e) => handleFileUpload(e, 'backId')}
                mb="24px"
                style={{
                  height: 'auto',
                  padding: '.4375rem .75rem',
                  fontSize: '.8125rem',
                  lineHeight: 1.5,
                  border: '1px solid #e1e5eb',
                  fontWeight: 300,
                  willChange: 'border-color, box-shadow',
                  borderRadius: '.25rem',
                  boxShadow: 'none',
                  transition:
                    'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                }}
                required
              />

              {/* Bank Name Input */}
              <Input
                id="bankName"
                placeholder="Bank Name"
                type="text"
                width={{ base: '100%', md: '300px' }} // Full width on small screens, 300px on medium and larger screens
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                mb="24px" // Margin at the bottom
                placeholdercolor={textplaceholdercolor}
                color={textColor}
                required
              />

              {/* Account Input */}
              <Input
                id="account"
                placeholder="Account Number"
                type="text"
                // Full width on small screens, 300px on medium and larger screens
                value={accountNumber}
                onChange={(e) => setAccount(e.target.value)}
                mb="24px" // Margin at the bottom
                placeholdercolor={textplaceholdercolor}
                color={textColor}
                required
              />

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="700"
                color="black"
                background="rgb(231, 231, 231)"
                border="none"
                borderRadius="8px"
                boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
                w="100%"
                h="50px"
                mb="24px"
                _hover={{ bg: '#003042', color: 'white' }}
                type="submit"
              >
                Sign Up
              </Button>
            </Box>
          </Flex>
        </FormControl>
      </form>
    </Card>
  );
}
