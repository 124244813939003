// Chakra imports
import {
  Text,
  useColorModeValue,
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Box,
  Flex,
  Grid,
  Select,
  keyframes,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import Card from 'components/card/Card.js';
import axios from 'axios';
import apiClient from '../../../../authclient/authclient';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function Projects() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const navigate = useNavigate();

  const [keyword, setKeyword] = React.useState('');
  const [sellerId, setSellerId] = React.useState('989');
  const [brandName, setBrandName] = React.useState('');
  const [amzSeller, setAmzSeller] = React.useState('');
  const [market, setMarket] = React.useState('');
  const [chineseSeller, setChineseSeller] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [productType, setProductType] = React.useState('');
  const [pic1, setPic1] = React.useState(null);
  const [pic2, setPic2] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [commission, setCommission] = React.useState('700');
  const [mercantileCommission, setMercantileCommission] = React.useState('175');
  const [saleLimit, setSaleLimit] = React.useState('');
  const [overallSaleLimit, setOverallSaleLimit] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [errorMsg, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  const [instruction, setInstruction] = React.useState(`
1. Review need to be submitted after 7 days of shipment received.    
2. Must use keyword for product search.
3. Buyer should be honest, scammer buyer is responsibility of agent.
4. Don't search with Brand Name.
5. A PM should provide buyer’s verification in case a PM asks for it in case of expensive products.
  `);

  const [refundConditions, setRefundConditions] = React.useState(`
1. Refund will be processed on 5 star review live on amazon.
2. Product cost + pp fee (Refund time could be 96 to 120 hours).
  `);

  const [commissionConditions, setCommissionConditions] = React.useState(`
1. Full Commission will be paid on mature leads.
2. Mature lead will be considered those orders which are refunded against 5 star reviews.
3. Not included deleted and feedback.
4. On deleted, commission will be paid as per Mercantile rule.
  `);

  const textColor = useColorModeValue('black', 'white');

  const handleFileUpload = async (event, type) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      setError('Please select an image to upload');
      return;
    }

    const formData = new FormData();
    formData.append('key', '0c7a84592216317dc484e9758f6d7a23'); // Replace with your ImgBB API key
    formData.append('image', selectedFile);

    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        'https://api.imgbb.com/1/upload',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const displayUrl = response.data.data.display_url;
      switch (type) {
        case 'ProductPic':
          setPic1(displayUrl);
          break;
        case 'AmzProductpic':
          setPic2(displayUrl);
          break;
        default:
          setError('Unknown upload type.');
      }
    } catch (error) {
      setError(`Error uploading ${type}. Please try again.`);
      setSuccess('');
    } finally {
      setLoading(false); // Ensure loading is reset
    }
  };

  const loadingAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;
  const handleClick = () => setShow(!show);

  const submitForm = async (event) => {
    event.preventDefault();

    if (!keyword) {
      setError('Keyword is required.');
      return;
    }
    if (!brandName) {
      setError('Brand name is required.');
      return;
    }
    if (!amzSeller) {
      setError('Amazon seller is required.');
      return;
    }
    if (!market) {
      setError('Market is required.');
      return;
    }
    if (!category) {
      setError('Category is required.');
      return;
    }
    if (!productType) {
      setError('Product type is required.');
      return;
    }
    if (!commission) {
      setError('Commission is required.');
      return;
    }
    if (!mercantileCommission) {
      setError('Mercantile commission is required.');
      return;
    }
    if (!instruction) {
      setError('Instruction is required.');
      return;
    }

    try {
      const response = await apiClient.post('store_product', {
        keyword,
        sellerId,
        brandName,
        amzSeller,
        market,
        chineseSeller,
        category,
        productType,
        image1Url: pic1,
        image2Url: pic2,
        commission,
        mercantileCommission,
        instructions: instruction,
        refundConditions,
        commissionConditions,
        saleLimitPerDay: saleLimit,
        overAllSaleLimit: overallSaleLimit,
      });

      if (response.data && response.data.message) {
        setSuccess(response.data.message);
        Swal.fire({
          title: 'Success!',
          text: 'Product updated successfully!',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
        setError('');
      } else {
        setError(response.data.message);
        setSuccess('');
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        navigate('/login'); // Redirect to login if 403 Forbidden error occurs
      }
      setError(
        error.message || 'An error occurred while processing your request.',
      );
      setSuccess('');
      Swal.fire({
        title: 'Error!',
        text: errorMsg || 'An unexpected error occurred. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <Card>
      <Text
        color={textColorPrimary}
        fontWeight="bold"
        fontSize="2xl"
        mt="0px"
        mb="25px"
      >
        Add New Product
      </Text>
      <form onSubmit={submitForm}>
        <FormControl>
          {/* {errorMsg && (
            <Text
              fontSize="sm"
              color="#721c24"
              bg="#f8d7da"
              borderColor="#f5c6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {errorMsg}
            </Text>
          )}
          {success && (
            <Text
              fontSize="sm"
              color="#155724"
              bg="#d4edda"
              borderColor="#c3e6cb"
              padding=".75rem"
              mb="1rem"
              borderRadius=".25rem"
            >
              {success}
            </Text>
          )} */}
          {loading && (
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="rgba(0, 0, 0, 0.8)"
              zIndex="999"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                {[
                  'red.500',
                  'blue.500',
                  'green.500',
                  'yellow.500',
                  'purple.500',
                ].map((color, index) => (
                  <Box
                    key={index}
                    bg={color}
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                    marginX="5px"
                    animation={`${loadingAnimation} 1s infinite ease-in-out`}
                    animationdelay={`${index * 0.2}s`}
                  />
                ))}
              </Box>
              <Text
                fontSize="3xl"
                color="white"
                mt={1}
                fontWeight="bold"
                textAlign="center"
                textShadow="0 0 5px rgba(255, 255, 255, 0.8), 0 0 10px rgba(0, 255, 255, 0.6)"
                letterSpacing="1px"
              >
                File Uploading...
              </Text>
            </Box>
          )}

          <Flex direction={{ base: 'column', md: 'row' }} mb="24px">
            <Box flex="1">
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  width={{ base: '100%', md: '300px' }} // Full width on small screens, 300px on medium screens and larger
                  type="text"
                  placeholder="Keyword"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  required
                />
              </Grid>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  type="text"
                  placeholder="Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e.target.value)}
                  required
                />
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  type="text"
                  placeholder="AMZ Seller ( Sold By )"
                  value={amzSeller}
                  onChange={(e) => setAmzSeller(e.target.value)}
                  required
                />
              </Grid>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Select
                  mb="20px"
                  width={{ base: '100%', md: '310px' }}
                  value={market}
                  onChange={(e) => setMarket(e.target.value)}
                  required
                >
                  {[
                    { value: 'US', label: 'US', fullName: 'United States' },
                    { value: 'UK', label: 'UK', fullName: 'United Kingdom' },
                    { value: 'DE', label: 'DE', fullName: 'Germany' },
                    { value: 'IT', label: 'IT', fullName: 'Italy' },
                    { value: 'CA', label: 'CA', fullName: 'Canada' },
                    { value: 'ES', label: 'ES', fullName: 'Spain' },
                    { value: 'AUS', label: 'AUS', fullName: 'Australia' },
                    { value: 'JAPAN', label: 'JAPAN', fullName: 'Japan' },
                    { value: 'KSA', label: 'KSA', fullName: 'Saudi Arabia' },
                    {
                      value: 'UAE',
                      label: 'UAE',
                      fullName: 'United Arab Emirates',
                    },
                    { value: 'General', label: 'General', fullName: 'General' },
                    { value: 'FR', label: 'FR', fullName: 'France' },
                    { value: 'Mexico', label: 'Mexico', fullName: 'Mexico' },
                    { value: 'Russia', label: 'Russia', fullName: 'Russia' },
                    { value: 'Sweden', label: 'Sweden', fullName: 'Sweden' },
                    {
                      value: 'Netherlands',
                      label: 'Netherlands',
                      fullName: 'Netherlands',
                    },
                    {
                      value: 'US-High Commission',
                      label: 'US-High Commission',
                      fullName: 'United States High Commission',
                    },
                    {
                      value: 'UK-High Commission',
                      label: 'UK-High Commission',
                      fullName: 'United Kingdom High Commission',
                    },
                    {
                      value: 'Walmart-US',
                      label: 'Walmart-US',
                      fullName: 'Walmart United States',
                    },
                    { value: 'Turkey', label: 'Turkey', fullName: 'Turkey' },
                    {
                      value: 'Kindle Books',
                      label: 'Kindle Books',
                      fullName: 'Kindle Books',
                    },
                    { value: 'ETSY', label: 'ETSY', fullName: 'ETSY' },
                    {
                      value: 'Singapore',
                      label: 'Singapore',
                      fullName: 'Singapore',
                    },
                    { value: 'India', label: 'India', fullName: 'India' },
                    {
                      value: 'Kindle US',
                      label: 'Kindle US',
                      fullName: 'Kindle United States',
                    },
                    {
                      value: 'eBay US',
                      label: 'eBay US',
                      fullName: 'eBay United States',
                    },
                    {
                      value: 'Walmart-UK',
                      label: 'Walmart-UK',
                      fullName: 'Walmart United Kingdom',
                    },
                    { value: 'Poland', label: 'Poland', fullName: 'Poland' },
                    {
                      value: 'TikTok US',
                      label: 'TikTok US',
                      fullName: 'TikTok United States',
                    },
                    {
                      value: 'Walmart-CA',
                      label: 'Walmart-CA',
                      fullName: 'Walmart Canada',
                    },
                    {
                      value: 'Ali Express',
                      label: 'Ali Express',
                      fullName: 'Ali Express',
                    },
                    {
                      value: 'Temu-US',
                      label: 'Temu-US',
                      fullName: 'Temu United States',
                    },
                    {
                      value: 'Otto-DE',
                      label: 'Otto-DE',
                      fullName: 'Otto Germany',
                    },
                    {
                      value: 'Temu-UK',
                      label: 'Temu-UK',
                      fullName: 'Temu United Kingdom',
                    },
                    {
                      value: 'SHEIN-US',
                      label: 'SHEIN-US',
                      fullName: 'SHEIN United States',
                    },
                    {
                      value: 'Temu-DE',
                      label: 'Temu-DE',
                      fullName: 'Temu Germany',
                    },
                  ].map((market, index) => (
                    <option key={index} value={market.value}>
                      {market.fullName}
                    </option>
                  ))}
                </Select>

                <InputGroup size="md">
                  <Input
                    color={textColor}
                    fontSize="sm"
                    mb="20px"
                    type="text"
                    placeholder="Chinese Seller"
                    value={chineseSeller}
                    onChange={(e) => setChineseSeller(e.target.value)}
                    required
                  />
                  <InputRightElement>
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: 'pointer' }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputRightElement>
                </InputGroup>
              </Grid>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Select
                  mb="20px"
                  width={{ base: '100%', md: '280px' }}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  required
                >
                  {[
                    'Electronics',
                    'Health & Beauty',
                    'Baby products',
                    'Fashion',
                    'Gaming devices',
                    'Home & Kitchen',
                  ].map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </Select>

                <Select
                  mb="20px"
                  value={productType}
                  onChange={(e) => {
                    setProductType(e.target.value);
                  }}
                  required
                >
                  <option value="all" disabled>
                    Type
                  </option>
                  <option value="Text Review">Text Review</option>
                  <option value="Top Reviewer">Top Reviewer</option>
                  <option value="No review">No review</option>
                  <option value="Feedback">Feedback</option>
                  <option value="Rating">Rating</option>
                  <option value="RAS">RAS</option>
                  <option value="RAO">RAO</option>
                  <option value="Seller Testing">Seller Testing</option>
                  <option value="Pic Review">Pic Review</option>
                  <option value="Vid Review">Vid Review</option>{' '}
                </Select>
              </Grid>

              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  color={textColor}
                  mb="20px"
                  type="file"
                  name="pic1"
                  sx={{
                    height: 'auto',
                    padding: '.4375rem .75rem',
                    fontSize: '.8125rem',
                    lineHeight: 1.5,
                    border: '1px solid #e1e5eb',
                    fontWeight: 300,
                    willChange: 'border-color, box-shadow',
                    borderRadius: '.25rem',
                    boxShadow: 'none',
                    transition:
                      'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                  }}
                  onChange={(e) => handleFileUpload(e, 'ProductPic')}
                  required
                />
                <Input
                  color={textColor}
                  mb="20px"
                  type="file"
                  name="pic2"
                  sx={{
                    height: 'auto',
                    padding: '.4375rem .75rem',
                    fontSize: '.8125rem',
                    lineHeight: 1.5,
                    border: '1px solid #e1e5eb',
                    fontWeight: 300,
                    willChange: 'border-color, box-shadow',
                    borderRadius: '.25rem',
                    boxShadow: 'none',
                    transition:
                      'box-shadow 250ms cubic-bezier(.27,.01,.38,1.06), border 250ms cubic-bezier(.27,.01,.38,1.06)',
                  }}
                  onChange={(e) => handleFileUpload(e, 'AmzProductpic')}
                  required
                />
              </Grid>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  width={{ base: '100%', md: '290px' }}
                  type="number"
                  placeholder="Commission Amount (Minimum 700 Rs)"
                  value={commission}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value); // Convert to float for consistent state handling

                    // Ensure the value is valid and update state only if it is a valid number
                    if (value === '' || !isNaN(value)) {
                      setCommission(value); // Keep as string for now (or convert to number if needed)
                    }
                  }}
                  min="700"
                  required
                />
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  type="number"
                  placeholder="Mercantile Commission"
                  value={mercantileCommission}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value); // Convert to float for consistent state handling

                    // Ensure the value is valid and update state only if it is a valid number
                    if (value === '' || !isNaN(value)) {
                      setMercantileCommission(value); // Keep as string for now (or convert to number if needed)
                    }
                  }}
                  min="175"
                  required
                />{' '}
              </Grid>

              <Textarea
                color={textColor}
                fontSize="sm"
                mb="20px"
                type="text"
                height="190px"
                placeholder="Instruction"
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
                required
              />
            </Box>

            <Box flex="1" pl={{ md: '12px' }}>
              <Textarea
                color={textColor}
                fontSize="sm"
                mb="20px"
                type="text"
                height="150px"
                placeholder="Instruction"
                value={commissionConditions}
                onChange={(e) => setCommissionConditions(e.target.value)}
                required
              />

              <Textarea
                color={textColor}
                fontSize="sm"
                mb="20px"
                type="text"
                height="150px"
                placeholder="Instruction"
                value={refundConditions}
                onChange={(e) => setRefundConditions(e.target.value)}
                required
              />
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
                gap={2}
              >
                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  type="number" // Changed to 'number' for numeric input
                  placeholder="Daily Sale Limit"
                  value={saleLimit}
                  onChange={(e) => setSaleLimit(e.target.value)}
                  required
                  min="0" // Optional: ensures only positive numbers
                />

                <Input
                  color={textColor}
                  fontSize="sm"
                  mb="20px"
                  type="number" // Changed to 'number' for numeric input
                  placeholder="Overall Sale Limit"
                  value={overallSaleLimit}
                  onChange={(e) => setOverallSaleLimit(e.target.value)}
                  required
                  min="0" // Optional: ensures only positive numbers
                />
              </Grid>

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="700"
                color="black"
                background="rgb(231, 231, 231)"
                border="none"
                borderRadius="8px"
                boxShadow="0 3px 1px 1px rgba(64, 60, 67, 0.24)"
                w="100%"
                h="50px"
                mb="24px"
                _hover={{ bg: '#003042', color: 'white' }}
                type="submit"
              >
                Add New
              </Button>
            </Box>
          </Flex>
        </FormControl>
      </form>
    </Card>
  );
}
