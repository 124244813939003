import {
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
} from '@chakra-ui/react';
import { MdEdit, MdClose } from 'react-icons/md';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Card from 'components/card/Card.js';
import Information from 'views/admin/profile/components/Information';

// Your existing state and other imports...
export default function GeneralInformation({ userData, ...rest }) {
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(userData.name || '');
  const [gender, setGender] = useState(userData.gender || '');
  const [role, setRole] = useState(userData.userRole || '');
  const [phoneNumber, setPhoneNumber] = useState(userData.phone || '');
  const [address, setAddress] = useState(userData.address || '');
  const [status, setStatus] = useState(userData.status || '');
  const [bankName, setBankName] = useState(userData.bankName || '');
  const [accountNumber, setAccountNumber] = useState(
    userData.accountNumber || '',
  );
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  return (
    <Card
      mb={{ base: '0px', '2xl': '20px' }}
      maxWidth="100%"
      padding="20px"
      {...rest}
    >
      {errorMsg && (
        <Text
          fontSize="sm"
          mb="8px"
          color="#721c24"
          backgroundColor="#f8d7da"
          borderColor="#f5c6cb"
          padding=".75rem 1.25rem"
          marginBottom="1rem"
          border="1px solid transparent"
          borderRadius=".25rem"
        >
          {errorMsg}
        </Text>
      )}
      {success && (
        <Text
          fontSize="sm"
          mb="8px"
          color="#155724"
          backgroundColor="#d4edda"
          borderColor="#c3e6cb"
          padding=".75rem 1.25rem"
          marginBottom="1rem"
          border="1px solid transparent"
          borderRadius=".25rem"
        >
          {success}
        </Text>
      )}
      <HStack spacing="20px" mt="10px">
        <Text fontWeight="bold" fontSize="2xl">
          User Profile Information
        </Text>
      </HStack>
      {/* Display information when not in edit mode */}
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
        <Information
          boxShadow={cardShadow}
          title="Name"
          value={userData.name || ''}
        />
        <Information
          boxShadow={cardShadow}
          title="Username"
          value={userData.userName || ''}
        />
        <Information
          boxShadow={cardShadow}
          title="Email"
          value={userData.email || ''}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
        <Information
          boxShadow={cardShadow}
          title="Phone Number"
          value={userData.phone || ''}
        />
        <Information
          boxShadow={cardShadow}
          title="Address"
          value={userData.address || ''}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
        <Information
          boxShadow={cardShadow}
          title="Gender"
          value={userData.gender || ''}
        />

        <Information
          boxShadow={cardShadow}
          title="Status"
          value={userData.status || ''}
        />

        <Information
          boxShadow={cardShadow}
          title="Role"
          value={userData.userRole || ''}
        />
      </SimpleGrid>
      {/* Display bank info when not in edit mode */}
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
        <Information
          boxShadow={cardShadow}
          title="Bank Name"
          value={userData.bankName || ''}
        />
        <Information
          boxShadow={cardShadow}
          title="Account Number"
          value={userData.accountNumber || ''}
        />
      </SimpleGrid>
    </Card>
  );
}
