// apiClient.js
import axios from 'axios';

// Function to retrieve token from cookies (or fallback to a hardcoded token if not found)
const getToken = () => {
  const token =
    document.cookie
      .split('; ')
      .find((row) => row.startsWith('token='))
      ?.split('=')[1] || null;

  if (!token) {
    // console.log('Cookie token not found. Using fallback token.');
  }

  // console.log('Retrieved Token:', token);
  return token;
};

// Set the base URL based on environment (localhost or production)
const baseUrl =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3001/'
    : 'http://192.168.100.35:3001/';

const apiClient = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Allow cookies to be sent with cross-origin requests
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // console.log('Token added to request:', token);
    }
    // console.log('Request config:', config);
    return config;
  },
  (error) => {
    //console.error('Request error:', error);
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(
  (response) => {
    // console.log('Response:', response);
    return response;
  },
  (error) => {
    //console.error('Response error:', error);
    return Promise.reject(error);
  },
);

export default apiClient;
