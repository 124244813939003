import { Box, Grid, Text, Skeleton } from "@chakra-ui/react"; 
import axios from "axios";
import React, { useEffect, useState } from "react";
import Banner from "views/admin/profile/components/ProfileBox";
import General from "views/admin/users/components/ProfileInformationEdit";
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/profile/male.jpg";
import apiClient from '../../../authclient/authclient';
import { useNavigate } from 'react-router-dom';

export default function Overview() {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try { 
      //user id is hardcoded now but it will replaced by the token 
      const userId = localStorage.getItem('userViewId');
      if (!userId) {
        navigate('/admin/userlist'); 
      }


    const response = await apiClient.get(`user/${userId}`);
            // console.log(response.data);
      setUserData(response.data || {}); // Use empty object as default
    } catch (err) {
      setError('Failed to fetch user data.');
      //console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Grid
          templateColumns={{ base: "1fr", lg: "0.6fr 1.2fr" }}
          templateRows={{ base: "repeat(1, 1fr)", lg: "1fr" }}
          gap={{ base: "15px", xl: "5px" }}
        >
          <Skeleton height="200px" borderRadius="md" />
          <Skeleton height="365px" borderRadius="md" />
        </Grid>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Grid
        templateColumns={{ base: "1fr", lg: "0.6fr 1.2fr" }}
        templateRows={{ base: "repeat(1, 1fr)", lg: "1fr" }}
        gap={{ base: "15px", xl: "5px" }}
      >
        <Banner
          gridArea={{ base: "1 / 1 / 2 / 2", lg: "1 / 1 / 1 / 2" }}
          banner={banner}
          avatar={avatar}
          name={userData.name || 'Unknown User'}
          job={userData.userRole || ''}
          posts={userData.posts || '0'}
          followers={userData.followers || '0'}
          following={userData.following || '0'}
        />
        
        <General 
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          minH="365px"
          width={{ base: "100%", lg: "100%" }}
          userData={userData}
          toggleEnableFunction={fetchUserData} 
        />
      </Grid>
    </Box>
  );
}
