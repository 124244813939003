import {
  SimpleGrid,
  useColorModeValue,
  Text,
  Button,
  HStack,
  Select,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { MdEdit, MdClose, MdHistory } from 'react-icons/md';
import Card from 'components/card/Card.js';
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For making API requests
import Information from 'views/admin/profile/components/Information';
import apiClient from '../../../../authclient/authclient';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

export default function GeneralInformation({
  orderData,
  toggleEnableFunction,
  ...rest
}) {
  // const [orderHistory, SetOrderhistory] = useState([]);
  // SetOrderhistory();
  const orderHistory = orderData.statusHistory;
  // console.log(orderData);
  const cardShadow = useColorModeValue(
    '0px 18px 40px rgba(112, 144, 176, 0.12)',
    'unset',
  );

  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Check user role in localStorage when component mounts
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, []);
  const [errorMsg, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    order_number: orderData?.order_number || '',
    customer_email: orderData?.customer_email || '',
    product_id: orderData?.product_id || '',
    amz_review_link: orderData?.amz_review_link || '',
    market: orderData?.market || '',
    status: orderData?.status || '',
    seller_id: orderData?.seller_id || '',
    remarks: orderData?.remarks || '',
    order_date: orderData?.order_date || '', // Correct initialization for date fields
    review_date: orderData?.review_date || '', // Correct initialization for date fields
    refund_date: orderData?.refund_date || '', // Correct initialization for date fields
    commission: orderData?.commission || '', // Add commission field
    last_update_date: orderData?.last_update_date || '', // Correct initialization for date fields
  });
  useEffect(() => {
    // Initialize formData with orderData when component mounts
    setFormData({
      order_number: orderData?.order_number || '',
      customer_email: orderData?.customer_email || '',
      product_id: orderData?.product_id || '',
      amz_review_link: orderData?.amz_review_link || '',
      market: orderData?.market || '',
      status: orderData?.status || '',
      seller_id: orderData?.seller_id || '',
      remarks: orderData?.remarks || '',
      order_date: orderData?.order_date || '',
      review_date: orderData?.review_date || '',
      refund_date: orderData?.refund_date || '',
      commission: orderData?.commission || '', // Ensure commission is set correctly
      last_update_date: orderData?.last_update_date || '', // Correct initialization for date fields
    });
  }, [orderData]);

  const handleToggle = () => {
    setIsEditing(!isEditing);
  };
  const handleHistoryToggle = () => {
    setIsHistoryVisible(!isHistoryVisible); // Toggle visibility of history
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('form:', formData);

    try {
      const response = await apiClient.put(
        `updateOrder/${orderData?._id}`,
        formData,
      );
      // console.log('Order updated successfully:', response.data);

      setSuccess('Order updated successfully!');
      setIsEditing(false);
      Swal.fire({
        title: 'Success!',
        text: 'Your order has been successfully updated. Thank you for your continued trust in our service!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    } catch (error) {
      if (error.response) {
        setError(
          error.response.data.message ||
            'Failed to update user. Please try again.',
        );
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      Swal.fire({
        title: 'Error!',
        text: errorMsg || 'An unexpected error occurred. Please try again.',
        icon: 'error',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });
    }
    toggleEnableFunction();
  };

  return (
    <Card
      mb={{ base: '0px', '2xl': '20px' }}
      maxWidth="100%"
      padding="20px"
      {...rest}
    >
      <HStack spacing="20px" mt="8px">
        <Text fontWeight="bold" fontSize="2xl">
          {isEditing ? 'Order Update' : 'Order Information'}
        </Text>

        <Button
          onClick={handleToggle}
          colorScheme="none"
          border="transparent"
          backgroundColor="transparent"
          color={isEditing ? 'red' : 'gray.500'}
          leftIcon={isEditing ? <MdClose color="red" /> : <MdEdit />}
          _hover={{
            backgroundColor: isEditing
              ? 'rgba(255, 0, 0, 0.1)' // Hover effect for "edit" mode
              : 'rgba(0, 0, 255, 0.1)', // Hover effect for "view" mode
          }}
          display={
            userRole === 'Pm'
              ? ['Ordered', 'OnHold'].includes(orderData?.status)
                ? 'inline-flex'
                : 'none'
              : userRole === 'Seller'
              ? orderData?.status !== 'Completed'
                ? 'inline-flex'
                : 'none'
              : ['Admin', 'SuperAdmin'].includes(userRole)
              ? 'inline-flex'
              : 'none'
          }
        >
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>

        {/* History button placed in top-right corner */}
        <Button
          onClick={handleHistoryToggle}
          colorScheme="none"
          border="transparent"
          backgroundColor="transparent"
          color={isEditing ? 'red' : 'gray.500'}
          leftIcon={<MdHistory boxsize="100px" />} // Increased icon size
          _hover={{
            backgroundColor: isEditing
              ? 'rgba(255, 0, 0, 0.1)' // Red hover effect
              : 'rgba(0, 0, 255, 0.1)', // Blue hover effect
          }}
          aria-label="Toggle Order History"
          position="absolute" // Absolute position to place it in top-right
          top="10px" // Top offset
          right="10px" // Right offset
        />
      </HStack>

      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="10px">
            <FormControl>
              <FormLabel>Order Number</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="text"
                name="order_number"
                placeholder="Order Number"
                required
                value={formData.order_number}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Customer Email</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="email"
                name="customer_email"
                placeholder="Customer Email"
                required
                value={formData.customer_email}
                onChange={handleChange}
                isReadOnly={userRole === 'Seller' || userRole === 'Pm'}
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="10px">
            <FormControl>
              <FormLabel>Product ID</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="text"
                name="product_id"
                placeholder="Product ID"
                required
                value={formData.product_id}
                onChange={handleChange}
                // Set the field to readonly for Seller, PM, and Admin roles
                isReadOnly={
                  userRole === 'Seller' ||
                  userRole === 'Pm' ||
                  userRole === 'Admin'
                }
              />
            </FormControl>

            <FormControl>
              <FormLabel>AMZ Review Link</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="text"
                name="amz_review_link"
                placeholder="AMZ Review Link"
                value={formData.amz_review_link}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Market</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="text"
                name="market"
                placeholder="Market (e.g., US, KSA)"
                required
                value={formData.market}
                onChange={handleChange}
                isReadOnly={
                  userRole === 'Seller' ||
                  userRole === 'Pm' ||
                  userRole === 'Admin'
                }
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="10px">
            <FormControl>
              <FormLabel>Order Status</FormLabel>
              <Select
                fontSize="sm"
                mb="10px"
                name="status"
                placeholder="Select Status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                {/* Show options based on user role */}
                {userRole === 'Pm' && (
                  <>
                    <option value="Ordered">Ordered</option>
                    <option value="Reviewed">Reviewed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="OnHold">OnHold</option>
                  </>
                )}

                {userRole === 'Seller' && (
                  <>
                    <option value="Ordered">Ordered</option>
                    <option value="Reviewed">Reviewed</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Refunded">Refunded</option>
                    <option value="OnHold">OnHold</option>
                    <option value="Deleted">Deleted</option>
                  </>
                )}

                {/* Default options for other roles */}
                {userRole !== 'Pm' && userRole !== 'Seller' && (
                  <>
                    <option value="Ordered">Ordered</option>
                    <option value="Reviewed">Reviewed</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Refunded">Refunded</option>
                    <option value="OnHold">OnHold</option>
                    <option value="Deleted">Deleted</option>
                    <option value="Completed">Completed</option>
                    <option value="Cancelled">Cancelled</option>
                  </>
                )}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Seller ID</FormLabel>
              <Input
                color="gray.400"
                fontSize="sm"
                mb="10px"
                type="text"
                name="seller_id"
                placeholder="Seller ID"
                required
                value={formData.seller_id}
                onChange={handleChange}
                isReadOnly={
                  userRole === 'Seller' ||
                  userRole === 'Pm' ||
                  userRole === 'Admin'
                }
              />
            </FormControl>
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, md: 1 }}
            gap="20px"
            mt="10px"
            mb="20px"
          >
            <FormControl>
              <FormLabel>Remarks</FormLabel>
              <Textarea
                color="gray.400"
                fontSize="sm"
                mb="10px"
                height="150px"
                name="remarks"
                placeholder="Enter remarks here"
                value={formData.remarks}
                onChange={handleChange}
                rows={4}
              />
            </FormControl>
          </SimpleGrid>

          <Button
            type="submit"
            fontSize="sm"
            fontWeight="700"
            w="100%"
            h="50px"
            mb="20px"
            _hover={{ bg: '#003042', color: 'white' }}
          >
            Update Order
          </Button>
        </form>
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="15px">
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Order Number
                </Text>
              }
              value={orderData?.order_number || ''}
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Customer Email
                </Text>
              }
              value={orderData?.customer_email || ''}
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Product ID
                </Text>
              }
              value={orderData?.product_id || ''}
            />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  AMZ Review Link
                </Text>
              }
              value={orderData?.amz_review_link || ''}
            />

            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Seller ID
                </Text>
              }
              value={orderData?.seller_id || ''}
            />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 3 }} gap="20px" mt="20px">
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Order Status
                </Text>
              }
              value={orderData?.status || ''}
            />

            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Market
                </Text>
              }
              value={orderData?.market || ''}
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Commission
                </Text>
              }
              value={orderData?.commission || ''}
            />
          </SimpleGrid>

          <SimpleGrid
            columns={{ base: 1, md: 4 }}
            gap="20px"
            mt="20px"
            mb="20px"
          >
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Order Date
                </Text>
              }
              value={
                orderData?.order_date
                  ? format(
                      new Date(orderData.order_date),
                      'MM/dd/yyyy HH:mm:ss',
                    )
                  : ''
              }
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Review Date
                </Text>
              }
              value={
                orderData?.review_date
                  ? format(
                      new Date(orderData.review_date),
                      'MM/dd/yyyy HH:mm:ss',
                    )
                  : ''
              }
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Refund Date
                </Text>
              }
              value={
                orderData?.refund_date
                  ? format(
                      new Date(orderData.refund_date),
                      'MM/dd/yyyy HH:mm:ss',
                    )
                  : ''
              }
            />
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="lg" fontWeight="bold" mb="10px">
                  Last Update Date
                </Text>
              }
              value={
                orderData?.last_update_date
                  ? format(
                      new Date(orderData.last_update_date),
                      'MM/dd/yyyy HH:mm:ss',
                    )
                  : ''
              }
            />
          </SimpleGrid>

          {isHistoryVisible && (
            <Information
              boxShadow={cardShadow}
              title={
                <Text fontSize="xl" fontWeight="bold" mb="10px">
                  Order Status History
                </Text>
              }
              value={
                <div>
                  {orderHistory.length > 0 ? (
                    orderHistory.map((history, index) => (
                      <Text key={index} mb="10px" fontSize="lg">
                        Order moved from{' '}
                        <Text as="span" fontWeight="bold" color="blue.500">
                          {history.previousStatus}
                        </Text>{' '}
                        to{' '}
                        <Text as="span" fontWeight="bold" color="blue.500">
                          {history.updatedToStatus}
                        </Text>{' '}
                        by{' '}
                        <Text as="span" fontWeight="bold" color="green.500">
                          {orderData.userName}
                        </Text>{' '}
                        on {new Date(history.updatedAt).toLocaleString()}
                      </Text>
                    ))
                  ) : (
                    <Text>No order history available.</Text>
                  )}
                </div>
              }
              mb="20px"
            />
          )}

          <Information
            boxShadow={cardShadow}
            title={
              <Text fontSize="xl" fontWeight="bold" mb="10px">
                Remarks
              </Text>
            }
            value=""
          />
        </>
      )}
    </Card>
  );
}
